(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrTask', TrTask);

	TrTask.$inject = ['$resource', 'DateUtils'];

	function TrTask($resource, DateUtils) {
		var resourceUrl = 'api/tr-tasks/:id';

		return $resource(resourceUrl, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);

					}
					return data;
				}
			},
			'update': {
				method: 'PUT'
			}
		});
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskGeofence', TrTaskGeofence);

	TrTaskGeofence.$inject = ['$resource'];

	function TrTaskGeofence($resource) {
		var service = $resource('/api/tr-tasks/geofence/:geofenceId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskCustomTime', TrTaskCustomTime);

	TrTaskCustomTime.$inject = ['$resource'];

	function TrTaskCustomTime($resource) {
		var service = $resource('/api/tr-tasks-custom/:fromDate/:toDate', {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskGeofenceCustomTime', TrTaskGeofenceCustomTime);

	TrTaskGeofenceCustomTime.$inject = ['$resource'];

	function TrTaskGeofenceCustomTime($resource) {
		var service = $resource('/api/tr-tasks-custom/geofence/:geofenceId/:fromDate/:toDate', {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrTaskCheckAsset', TrTaskCheckAsset);

	TrTaskCheckAsset.$inject = ['$resource', 'DateUtils'];

	function TrTaskCheckAsset($resource, DateUtils) {
		var resourceUrl = 'api/assets/search/:name';

		return $resource(resourceUrl, {}, {
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
		});
	}
})();


(function () {
    'use strict';
    angular.module('trakeyeApp').factory('TasksForAsset', TasksForAsset);

    TasksForAsset.$inject = ['$resource'];

    function TasksForAsset($resource) {
        var resourceUrl = 'api/tasks-for-asset/:assetId';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-tasks/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('getTaskImage', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/trakeyetaskimages/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('getNearbyFixed', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id,data,callback) {
			$http.post('api/assets/nearby-fixed/' + id,data).then(function (response) {
				callback(response.data);
			}); 
		};
		return service;
	}]);
})(); 

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskAssetsById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-tasks-assets/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TaskAttributeMappingId', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/custom-task-type-mapper/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

/*
(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskById', TrTaskById);

	TrTaskById.$inject = [ '$resource' ];

	function TrTaskById($resource) {
		var service = $resource('/api/tr-tasks/:id', {}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}

})();*/

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskCountByGroup', ['$http', function ($http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/taskcountbygroup').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('DownloadTaskReports', ['$http', function ($http) {

		var service = {};
		service.download = function (callback) {
			$http.get('api/download-tasks').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskCountByStatus', ['$http', function ($http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/taskcountbystatus').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskPriority', TrTaskPriority);

	TrTaskPriority.$inject = ['$resource'];

	function TrTaskPriority($resource) {
		var service = $resource('/api/tr-tasks/priority/:priority/:geofenceId', {
			priority: '@priorirty'
		}, {geofenceId : '@geofenceId'},{}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskAttendance', TrTaskAttendance);

	TrTaskAttendance.$inject = ['$resource'];

	function TrTaskAttendance($resource) {
		var service = $resource('/api/tr-tasks/attendance/:login/:fromTime/:toTime/:taskStatus',
			{}, {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskSearch', TrTaskSearch);

	TrTaskSearch.$inject = ['$resource'];

	function TrTaskSearch($resource) {
		var service = $resource('/api/tr-tasks/searchvalue/:search', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskSearchCustom', TrTaskSearchCustom);

	TrTaskSearchCustom.$inject = ['$resource'];

	function TrTaskSearchCustom($resource) {
		var service = $resource('/api/tr-tasks-custom/searchvalue/:search/:fromDate/:toDate', {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskGeofenceSearch', TrTaskGeofenceSearch);

	TrTaskGeofenceSearch.$inject = ['$resource'];

	function TrTaskGeofenceSearch($resource) {
		var service = $resource('/api/tr-tasks/geofence-searchvalue/:geofenceId/:search', {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskGeofenceSearchCustom', TrTaskGeofenceSearchCustom);

	TrTaskGeofenceSearchCustom.$inject = ['$resource'];

	function TrTaskGeofenceSearchCustom($resource) {
		var service = $resource('/api/tr-tasks-custom/geofence-searchvalue/:geofenceId/:search/:fromDate/:toDate', {}, {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrTaskGroup', TrTaskGroup);

	TrTaskGroup.$inject = ['$resource', 'DateUtils'];

	function TrTaskGroup($resource, DateUtils) {
		var resourceUrl = 'api/tr-tasks-group';

		return $resource(resourceUrl, {
			'query': {
				method: 'GET',
				isArray: true
			}
		});
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskGroupSearch', TrTaskGroupSearch);

	TrTaskGroupSearch.$inject = ['$resource'];

	function TrTaskGroupSearch($resource) {
		var service = $resource('/api/tr-tasks-group/searchvalue/:search', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskGroupPrioritySearch', TrTaskGroupPrioritySearch);

	TrTaskGroupPrioritySearch.$inject = ['$resource'];

	function TrTaskGroupPrioritySearch($resource) {
		var service = $resource('/api/tr-tasks-group/prioritysearch/:priority/:searchtext', {
			priority: '@priorirty',
			searchtext: '@searchtext'
		}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskGroupPriority', TrTaskGroupPriority);

	TrTaskGroupPriority.$inject = ['$resource'];

	function TrTaskGroupPriority($resource) {
		var service = $resource('/api/tr-tasks-group/priority/:priority', {
			priority: '@priorirty'
		}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskImages', TrTaskImages);

	TrTaskImages.$inject = ['$resource'];

	function TrTaskImages($resource) {
		var service = $resource('/api/task-images/:taskId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTasksForType', TrTasksForType);

	TrTasksForType.$inject = ['$resource'];

	function TrTasksForType($resource) {
		var service = $resource('/api/trtasksbytype/:taskTypeId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: false
			},
		});

		return service;
	}

})();
 (function () {
	'use strict';

	angular.module('trakeyeApp').factory('NearByBGOH', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAll = function (data,success,error) {
			$http.post('api/tr-tasks/nearby-bgoh-assets',data).then(function (response) {
				success(response.data);
			},function (response) {
				error(response.status);
			});
		};

		return service;
	}]);
})();  

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskRecordings', TrTaskRecordings);

	TrTaskRecordings.$inject = ['$resource'];

	function TrTaskRecordings($resource) {
		var service = $resource('/api/task-recordings/:taskId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('AllTaskTypes', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/task-alltypes').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskPrioritySearch', TrTaskPrioritySearch);

	TrTaskPrioritySearch.$inject = ['$resource'];

	function TrTaskPrioritySearch($resource) {
		var service = $resource('/api/tr-tasks/prioritysearch/:priority/:searchtext', {
			priority: '@priorirty',
			searchtext: '@searchtext'
		}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrTaskUpdateGroup', TrTaskUpdateGroup);

	TrTaskUpdateGroup.$inject = ['$resource'];

	function TrTaskUpdateGroup($resource) {
		var resourceUrl = 'api/tr-tasks/groups';

		return $resource(resourceUrl, {}, {
			'update': {
				method: 'PUT'
			}
		});
	}
})();
(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrTaskBulkDelete', TrTaskBulkDelete);

	TrTaskBulkDelete.$inject = ['$resource'];

	function TrTaskBulkDelete($resource) {
		var resourceUrl = 'api/tr-tasks/bulk/delete';

		return $resource(resourceUrl, {}, {
			'save': {
				method: 'POST'
			}
		});
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskListByRouteId', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-tasks-by-sectionId/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskListIdBySectionId', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-tasks-list-by-sectionId/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('ValidationLogsBySectionId', ValidationLogsBySectionId);

    ValidationLogsBySectionId.$inject = ['$resource'];

    function ValidationLogsBySectionId($resource) {
        var service = $resource('/api/rollout/get-validation-logs-by-sectionid/:sectionId/', {}, {}, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
        });

        return service;
    }

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskUser', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.gettrtasksuser = function (callback) {
			$http.get('api/tr-tasks/live-logs').then(function (response) {
				callback(response.data);
			});
		};

		service.gettrtasksuserbyid = function (id, callback) {
			$http.get('api/tr-tasks/live-logs/' + id).then(function (response) {
				callback(response.data);
			});
		};

		service.searchtrtasksuserbyvalue = function (id, callback) {
			$http.get('api/tr-tasks/search/' + id).then(function (response) {
				callback(response.data);
			});
		};
		service.gettrtasksanduserbypriority = function (priority, callback) {
			$http.get('api/tr-tasks/live-logs/priority/' + priority).then(function (response) {
				callback(response.data);
			});
		};

		service.getAgents = function (callback) {
			$http.get('api/dashboard/agentdashboard').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskEdit', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getalltrtasks = function (callback) {
			$http.get('api/tr-alltasks').then(function (response) {
				callback(response.data);
			});
		};

		service.edittrtask = function (data, callback) {
			$http.post('api/tr-taskedit/' + data.userId + '/' + data.taskId, {

			}).then(function (response) {

				callback(response, response.headers);
			}, function (error) {

				callback(error, error.headers);
			});

			/*
			 * $http.post('api/tr-taskedit/'+data.userId+'/'+data.taskId).then(function(response){
			 * callback(response.data); });
			 */
		};

		return service;
	}]);
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrTaskUpload', TrTaskUpload);

	TrTaskUpload.$inject = ['$http'];

	function TrTaskUpload($http) {

		var service = {};
		service.trtaskupload = function (data, callback) {

			$http.post('api/taskimport', data, {
				transformRequest: angular.identity,
				headers: {
					'Content-Type': undefined
				}
			}).then(function (response) {

				callback(response, response.headers);
			}, function (error) {
				callback(error, error.headers);
			});
		};

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrGroupTaskSearchForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.searchtrtaskformap = function (search, callback) {
			$http.get('api/tr-grouptasksformap/searchvalue/' + search).then(function (response) {
				callback(response.data);
			});
		};
		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrGroupTaskForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAllTasks = function (callback) {
			$http.get('api/tr-grouptasksformap').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskSearchForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.searchtrtaskformap = function (search, callback) {
			$http.get('api/tr-tasksformap/searchvalue/' + search).then(function (response) {
				callback(response.data);
			});
		};

		service.prioritysearchtrtaskformap = function (priority, callback) {
			$http.get('api/tr-tasksformap/priority/' + priority).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();


(function () {
	'use strict';

	angular
		.module('trakeyeApp')
		.factory('TrTaskLastLocation', ['$rootScope', '$http', function ($rootScope, $http) {
			var service = {};

			service.getlastlogs = function (callback) {
				$http.get('api/location-logs/lastuserpath').then(function (response) {
					callback(response.data);
				});
			};
			return service;
		}]);



})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TasksForMapByAdmin', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.tasksformapbyadmin = function (adminid, callback) {
			$http.get('api/tr-tasksformap/' + adminid).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrTaskForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAllTasks = function (data, callback) {
			// $http.get('api/tr-tasksformap').then(function (response) {
			// 	callback(response.data);
			// });
			$http.post('api/tr-tasksformap', data).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('ShowTaskById', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.update = function (taskid, callback) {
            $http.put('api/show-task/' + taskid)
			.success(function (data, status, headers, config) {
				callback(status);
            })
            .error(function (data, status, header, config) {
				callback(status);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('HideTaskById', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.update = function (taskid, callback) {
            $http.put('api/hide-task/' + taskid)
			.success(function (data, status, headers, config) {
				callback(status);
            })
            .error(function (data, status, header, config) {
				callback(status);
            });
        };

        return service;
    }]);
})();


(function () {
    'use strict';

    angular.module('trakeyeApp').factory('getTask', [ '$http', function ($http) {
		
        var service, apiSectionId, apiRouteId;
        service = {};
        apiSectionId = "api/tr-tasks/section/";
        apiRouteId = "api/tr-tasks/route/";

        // Common function for fetching data by ID
        function fetchDataById(endpoint, id, callback) {
            $http
                .get(endpoint + id)
                .then(function (response) {
                    callback(response);
                })
                .catch(function (error) {
                    callback(error);
                });
        }

        // Get Section by ID
        service.bySectionId = function (id, callback) {
            fetchDataById(apiSectionId, id, callback);
        };

        // Get Task by Route ID
        service.byRouteId = function (id, callback) {
            fetchDataById(apiRouteId, id, callback);
        };

        return service;
    }]);
})();


(function () {
    'use strict';

    angular.module('trakeyeApp').factory('TaskLog', [ '$http', function ($http) {
		
        var service, apiCreateDailyLog, createDailyResourceTxn,createDailyMaterialTxn,apiEditDailyLog;
        service = {};
        //apiCreateDailyLog = "api/rollout/bulk-log-daily-task";
		apiCreateDailyLog = "api/rollout/log-daily-task"
		createDailyResourceTxn = "api/rollout/log-res-tx"
		createDailyMaterialTxn = "api/rollout/log-mtrl-tx"
		apiEditDailyLog = "api/rollout/log-daily-task"

        // Common function for fetching data by ID
        function fetchData(endpoint, payload, logId, success,error, str) {

			switch(str) {
				case 'POST' : $http
                .post(endpoint,payload)
                .then(function (response) {
                    success(response);
                })
                .catch(function (er) {
                    error(er);
                })
				break;

				case 'PUT' : $http
                .put(endpoint,payload)
                .then(function (response) {
                    success(response);
                })
                .catch(function (er) {
                    error(er);
                })
				break;

				default : console.log("DailyTaskLog Default Executed")
			}
            
        }

        service.post = function (payload, callback,err) {
            fetchData(apiCreateDailyLog, payload,null, callback,err,'POST');
        };

		service.update = function (payload, callback,err) {
            fetchData(apiEditDailyLog, payload,null, callback,err,'PUT');
        };

		service.postMaterialTxn = function (payload, callback) {
            fetchData(createDailyMaterialTxn, payload,null, callback,null,'POST');
        };

		service.postResourceTxn = function (payload, callback) {
            fetchData(createDailyResourceTxn, payload,null, callback,null,'POST');
        };

        return service;
    }]);
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('dailyTaskLog', dailyTaskLog);

	dailyTaskLog.$inject = ['$resource', 'DateUtils'];

	function dailyTaskLog($resource, DateUtils) {
		var resourceUrl = 'api/rollout/get-daily-task-logs/:id';

		return $resource(resourceUrl, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);

					}
					return data;
				}
			},
			'update': {
				method: 'PUT'
			}
		});
	}
})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('postRoutePoints', ['$http', function ($http) {

		var service = {};
		service.post = function (data,callback) {
			$http.post('api/routepoints',data).then(function (response) {
				callback(response.data);
			}).catch(function (err){
				callback(err)
			});
		};
		return service;
	}]);
})(); 


/* (function () {
	'use strict';

	angular.module('trakeyeApp').factory('dailyLogByTaskId', ['$http', function ($http) {

		var service = {};
		service.get = function (taskId,page,size,callback) {
			$http.get('api/rollout/get-daily-task-logs/'+taskId,{
				params: {
					page: page,
					size: size
				}}).then(function (response) {
				callback(response.data);
			}).catch(function (err){
				callback(err)
			});
		};
		
		return service;
	}]);
})();
 */

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('DailyLogByTaskIdWithType', DailyLogByTaskIdWithType);

	DailyLogByTaskIdWithType.$inject = ['$resource'];

	function DailyLogByTaskIdWithType($resource) {
		var service = $resource('/api/rollout/get-daily-task-logs/:id/type/:type', {
			id: '@id'
		}, {type : '@type'},{}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		}); 

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('DailyLogByTaskIdWithTypePagination', DailyLogByTaskIdWithTypePagination);

	DailyLogByTaskIdWithTypePagination.$inject = ['$resource'];

	function DailyLogByTaskIdWithTypePagination($resource) {
		
		var service = $resource('/api/rollout/get-daily-task-logs-page/:id/type/:type', {
			id: '@id'
		}, {type : '@type'},{}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('dailyLogByTaskId', dailyLogByTaskId);

	dailyLogByTaskId.$inject = ['$resource'];

	function dailyLogByTaskId($resource) {
		var resourceUrl = 'api/rollout/get-daily-task-logs/:id'

		return $resource(resourceUrl, {},{}, {
			'query': {
				method: 'GET',
				isArray: true
			},
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);

					}
					return data;
				}
			},
			'update': {
				method: 'PUT'
			}
		});
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('taskComment', ['$http', function ($http) {

		var service = {};

		service.post = function (payload,callback) {
			$http.post('api/tr-user-comments',payload).then(function (response) {
				callback(response.data);
			}).catch(function (err){
				callback(err)
			});
		};

		service.put = function (payload,callback) {
			$http.put('api/tr-user-comments',payload).then(function (response) {
				callback(response.data);
			}).catch(function (err){
				callback(err)
			});
		};

		service.getTaskLogsPaths = function (taskId,callback) {
			$http.get('api/rollout/daily-task-log-path/task/'+taskId).then(function (response) {
				callback(response.data);
			}).catch(function (err){
				callback(err)
			});
		};

		service.get = function (entityId,entityName,callback) {
			$http.get('api/tr-user-comments/'+entityId+'/'+entityName).then(function (response) {
				callback(response.data);
			}).catch(function (err){
				callback(err)
			});
		};

		service.delete = function (commentId,callback) {
			$http.delete('api/tr-user-comments/'+commentId).then(function (response) {
				callback(response.data);
			}).catch(function (err){
				callback(err)
			});
		};

		return service;
	}]);
})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('filterTask', ['$http', function ($http) {
		
		var service = {};
		service.routeFilter = function (page,size,payload,callback) {
			$http.post('api/tr-route-tasks',payload,{
				params: {
					page: page,
					size: size
				}
			}).then(function (response) {
				callback(response.data,response.headers);
			}).catch(function (err){
				callback(err)
			});
		};

		service.sectionFilter = function (payload,callback) {
			$http.post('api/tr-section-tasks',payload).then(function (response) {
				callback(response.data,response.headers);
			}).catch(function (err){
				callback(err)
			});
		};
		
		return service;
	}]);
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('DailyLogs', DailyLogs);

	DailyLogs.$inject = ['$resource'];

	function DailyLogs($resource) {
		var resourceUrl = 'api/rollout/log-daily-task/:id';

		return $resource(resourceUrl, {}, {
			'delete': {
				method: 'DELETE'
			},
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);

					}
					return data;
				}
			}
		});
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TaskPathById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-tasks-path/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();


(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrUserTask', TrUserTask);

	TrUserTask.$inject = ['$resource'];

	function TrUserTask($resource, DateUtils) {
		var resourceUrl = 'api/tr-user-tasks'

		return $resource(resourceUrl, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);

					}
					return data;
				}
			},
			'update': {
				method: 'PUT'
			}
		});
	}
})();

(function () {
    'use strict';
    angular.module('trakeyeApp').factory('FixedTaskLogExcelUpload', FixedTaskLogExcelUpload);

    FixedTaskLogExcelUpload.$inject = ['$http'];

    function FixedTaskLogExcelUpload($http) {

        var service = {};
        service.logupload = function (data, callback) {

            $http.post('api/importDailyLogsFromExcel', data, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined
                }
            }).then(function (response) {
                callback(response, response.headers);
            }, function (error) {
                callback(error, error.headers);
            });
        };

        return service;
    }

})();
