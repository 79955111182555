angular.module('trakeyeApp').directive('resourceTxnTab', ['SummaryPost','$rootScope','ParseLinks','Geofence','ResourceDates','ResourceByDates',function (SummaryPost,$rootScope,ParseLinks,Geofence,ResourceDates,ResourceByDates) {
    return {
      restrict: 'E',
      scope: {
        obj:'=',
      },
      templateUrl: 'app/components/View/resourceTxnTab.html',
      link: function (scope) {

        scope.RESOURCETX_EDIT = $rootScope.Roles.RESOURCETX_EDIT
        scope.entityId = scope.obj.id
        scope.entityName = scope.obj.name
        scope.sectionId = scope.obj.sectionId
        scope.page = 1
        scope.predicate = 'modified_date'
        scope.itemsPerPage = 10
        $('.select2').select2();
        
        SummaryPost.resTx({taskIds : [ scope.entityId ]}, function (response) {
          scope.resTxSummary = response
        })
        
        scope.transition = function() {
          loadAll()
        }
        //loadAll()
      function loadAll() {
       /*  Geofence.query({
            page: scope.page,
            size: scope.itemsPerPage,
            sort: sort()
        }, onSuccess, onError); */

        function sort() {
            var result = [scope.predicate + ',' + (scope.reverse ? 'asc' : 'desc')];
            return result;
        }

        function onSuccess(data, headers) {
            scope.links = ParseLinks.parse(headers('link'));
            scope.totalItems = headers('X-Total-Count');
            scope.queryCount = scope.totalItems;
            scope.resourceTxListView = []
            data.forEach(function (value) {
              scope.resourceTxListView.push(value);
            });
            //vm.page = pagingParams.page;
        }

        function onError(error) {
            console.log(error)
        }
    }
    scope.loadMaterialTxnDate = function () {
      ResourceDates.query({
        type: "task",
        id: scope.entityId,
        page: scope.page - 1,
        size: scope.itemsPerPage,
        sort: "last_modified_date,desc"
      }, function (data, headers) {
        scope.links = ParseLinks.parse(headers('link'));
        scope.totalItems = headers('X-Total-Count');
        scope.queryCount = scope.totalItems;
        scope.materialByDate = data.map(function (item) {
          return {
            timestamp: item[0],
            total: item[1],
            isExpand: false
          };
        });
      });
    };

    scope.loadMaterialTxnByDates = function (date) {
      ResourceByDates.query({
        type: 'task',
        id: scope.entityId,
        date: date,
        page: scope.page - 1,
        size: scope.itemsPerPage,
        sort: "last_modified_date,desc"
      }, function (data, headers) {
        scope.links = ParseLinks.parse(headers('link'));
        scope.totalItems = headers('X-Total-Count');
        scope.queryCount = scope.totalItems;
        scope.materialTxnByDates = data.map(function (item) {
          return {
            transactionId: item[0],
            timestamp: item[4],
            transactionName: item[1],
            transactionType: item[2],
            count: item[3],
            status: item[5],
          };
        });
      });
    };

    scope.toggleExpand = function (selectedTask) {
      angular.forEach(scope.materialByDate, function (task) {
        if (task === selectedTask) {
          task.isExpand = !task.isExpand;
          if (task.isExpand) {
            scope.loadMaterialTxnByDates(task.timestamp);
          } else {
            scope.materialTxnByDates = [];
          }
        } else {
          task.isExpand = false;
        }
      });
    };

    scope.transitionMainMaterial = function () {
      scope.loadMaterialTxnDate();
    };

    // Initial load
    scope.loadMaterialTxnDate();
      }
      
    };
  }]);
  