(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('TrVendorDetailController', TrVendorDetailController);

    TrVendorDetailController.$inject = ['$stateParams', 'TrVendor', '$scope', 'SummaryPost', 'MaterialDates', 'MaterialByDates', 'ParseLinks'];

    function TrVendorDetailController($stateParams, TrVendor, $scope, SummaryPost, MaterialDates, MaterialByDates, ParseLinks) {
        var vm = this;
        vm.load = load;
        //vm.trVendor = {};
        vm.groupedMaterialTxn = []
        vm.groupedResourceTxn = []
       
        vm.load($stateParams.id);

        function load (id) {
            TrVendor.get({id: id}, function(result) {
                vm.trVendor = result;
            });
        }

        $scope.tab = 1;

        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };

        $scope.setTab = function (newTab) {
            if (newTab == 1) {
                vm.load($stateParams.id);
            }

            if(newTab == 2){
                SummaryPost.mtrlTx({vendorIds : [ $stateParams.id ]}, function (response) {
                    if(response.length){
                            vm.groupedMaterialTxn = response.reduce(function(acc, obj)  {
                                var entry = acc.find(function(item) { return  item.projectId === obj.projectId && item.projectName === obj.projectName} );
                            
                                if (!entry) {
                                    entry = {
                                        projectId: obj.projectId,
                                        projectName: obj.projectName,
                                        details: []
                                    };
                                    acc.push(entry);
                                }
                    
                                entry.details.push(obj);
                            
                                return acc;
                            }, []);
                    }
                  })
            }

            if(newTab == 3){
                SummaryPost.resTx({vendorIds : [ $stateParams.id ]}, function (response) {
                    if(response.length){
                            vm.groupedResourceTxn = response.reduce(function(acc, obj)  {
                                var entry = acc.find(function(item) { return  item.projectId === obj.projectId && item.projectName === obj.projectName} );

                                if (!entry) {
                                    entry = {
                                        projectId: obj.projectId,
                                        projectName: obj.projectName,
                                        details: []
                                    };
                                    acc.push(entry);
                                }

                                entry.details.push(obj);

                                return acc;
                            }, []);
                    }
                  })
            }

            $scope.tab = newTab;
        }

        vm.expand = function (task,id) {

            if(id == 'material'){
                vm.groupedMaterialTxn.forEach(function (item) {
                    item.expand = false;
                });
            }else if(id == 'resource'){
                vm.groupedResourceTxn.forEach(function (item) {
                    item.expand = false;
                });
            }

            if (vm.toggle == task.projectId) {
                vm.toggle = undefined;
            } else {
                task.expand = true;
                vm.toggle = task.projectId;
            }
        }
        vm.page = 1;
        vm.predicate = 'modified_date';
        vm.itemsPerPage = 10;
        vm.materialTxnByDates = [];
        vm.materialByDate = [];
        vm.loadMaterialTxnDate = function () {
            MaterialDates.query({
                type: "vendor",
                id: $stateParams.id,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            }, function (data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.materialByDate = data.map(function (item) {
                    return {
                        timestamp: item[0],
                        total: item[1],
                        isExpand: false
                    };
                });

            });
        };

        vm.loadMaterialTxnDate();

        vm.loadMaterialTxnByDates = function (date) {
            MaterialByDates.query({
                type: 'vendor',
                id: $stateParams.id,
                date: date,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            }, function (data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.materialTxnByDates = data.map(function (item) {
                    return {
                        transactionId: item[0],
                        timestamp: item[4],
                        transactionName: item[1],
                        transactionType: item[2],
                        count: item[3],
                        status: item[5],
                    };
                });
            });
        };
        vm.toggleExpand = function (selectedTask) {
            angular.forEach(vm.materialByDate, function (task) {
                if (task === selectedTask) {
                    task.isExpand = !task.isExpand;
                    if (task.isExpand) {
                        vm.loadMaterialTxnByDates(task.timestamp);
                    } else {
                        vm.materialTxnByDates = [];
                    }
                } else {
                    task.isExpand = false;
                }
            });
        };
        vm.transitionMainMateril = function () {
            vm.loadMaterialTxnDate()
        }
    }
})();
