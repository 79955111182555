(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("EntityDumpController", EntityDumpController);

    EntityDumpController.$inject = [
        "Report",
        "myservice",
        "$interval",
        "$scope"
    ];

    function EntityDumpController(
        Report,
        myservice,
        $interval,
        $scope
    ) {
        var vm = this;
        vm.highlightRow = false
        if(myservice.getValue() == 'true'){
            vm.highlightRow = true;
        }
       
        
        callReportApi()

        vm.refresh = function(){
            callReportApi()
        }

        function callReportApi(){
            Report.getListEntity(function (response){
                if(response.length){
                    vm.displayList = response.reverse()
                }
            })
        }

        var myInterval = $interval(function() {
            // Code to be executed periodically
            callReportApi()
          }, 15000); // Interval duration in milliseconds
        
          // Listen for the $destroy event to clear the interval when the controller is destroyed
          $scope.$on('$destroy', function() {
            // Clear the interval when the controller is destroyed
            if (angular.isDefined(myInterval)) {
              $interval.cancel(myInterval);
            }
          });
        
        vm.expand = function(entity) {
            vm.displayList.forEach(function (item) {
                item.expand = false;
            });

            if (vm.toggle == entity.id) {
                vm.toggle = undefined;
            } else {
                entity.expand = true;
                vm.toggle = entity.id;

                Report.getEntityDetails(entity.id,function (response){
                    vm.expandResponse = response
                })

            }


        }

        vm.download = function(imgId,id,name){
            var temp = "Report"
            Report.downloadZip(id,imgId,function (data){
                var a = document.createElement("a");
               var file = new Blob([data], { type: 'application/zip' });

               var fileURL = URL.createObjectURL(file);
               a.href = fileURL;
               a.download = name ? name + ".zip" : temp + ".zip";
               a.click();
               window.URL.revokeObjectURL(fileURL);
            })
        }
    }
})();
