(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("RowTypeDialogController", RowTypeDialogController);

    RowTypeDialogController.$inject = [
        "$timeout",
        "$scope",
        "$stateParams",
        "$state",
        "entity",
        "RowType",
        "User",
        "RowTypeSearch",
        "AllCustomRowTypeAttribute",
    ];


    function RowTypeDialogController(
        $timeout,
        $scope,
        $stateParams,
        $state,
        entity,
        RowType,
        User,
        RowTypeSearch,
        AllCustomRowTypeAttribute
    ) {
        var vm = this;
        //rowtype attribute types
        $scope.attributetypes = ["INTEGER", "FLOAT", "STRING", "BOOLEAN"];
        //end
        vm.rowType = entity;
        vm.rowTypeImageName = rowTypeImageName;
        vm.decodeImage = decodeImage;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.customRowTypeAttributes = loadPage();
        vm.customAttributesMap = {};
        $scope.allimages = false;
        $scope.toggle = function () {
            $scope.allimages = !$scope.allimages;
        };
        vm.save = save;
        $(":file").filestyle({ buttonBefore: true });
        $(":file").filestyle("buttonText", "Browse File");



        vm.checkNumber;





        function rowTypeImageName(image) {
            vm.rowType.image = image;
        }


        function decodeImage(img) {
            return window.atob(img);
        }

        function loadPage() {
            AllCustomRowTypeAttribute.getAll(function (response) {
                vm.customRowTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    vm.customAttributesMap[arrayItem.name] =
                        arrayItem.customRowTypeAttributeValues;
                });
            });
        }

        //      vm.users = User.query();
        vm.items = [{ name: "", attributeDataType: {}, defaultValue: "" }];

        if (
            vm.rowType.rowTypeAttribute != null &&
            vm.rowType.rowTypeAttribute.length != 0
        ) {
            vm.items = vm.rowType.rowTypeAttribute;
        }
        var tempItems = [];
        vm.items.forEach(function (value) {
            if (value.rowCategory == null) {
                value.rowCategory = "DEFAULT";
            }
            tempItems.push(value);
        });

        vm.items = tempItems;
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }
        function save() {
            vm.isSaving = true;
            if (vm.rowType.id !== null) {
                vm.rowType.rowTypeAttribute = [];
                for (var i = 0; i < vm.items.length; i++) {
                    var attributes = { name: vm.items[i] };
                    vm.rowType.rowTypeAttribute.push({
                        rowCategory: vm.items[i].rowCategory,
                        id: vm.items[i].id,
                        mandatory: vm.items[i].mandatory,
                        name: vm.items[i].name,
                        attributeDataType: vm.items[i].attributeDataType,
                        defaultValue: vm.items[i].defaultValue,
                        rowTypeAttribOrder: vm.items[i].rowTypeAttribOrder
                    });
                }
                RowType.update(vm.rowType, onSaveSuccess, onSaveError);
            } else {
                vm.rowType.rowTypeAttribute = [];

                for (var i = 0; i < vm.items.length; i++) {
                    var attributes = { name: vm.items[i] };
                    vm.rowType.rowTypeAttribute.push({
                        rowCategory: vm.items[i].rowCategory,
                        id: vm.items[i].id,
                        mandatory: vm.items[i].mandatory,
                        name: vm.items[i].name,
                        attributeDataType: vm.items[i].attributeDataType,
                        defaultValue: vm.items[i].defaultValue,
                        rowTypeAttribOrder: vm.items[i].rowTypeAttribOrder
                    });
                }
                RowType.save(vm.rowType, onSaveSuccess, onSaveError);
            }
        }

        $("#rowTypeImage").change(function () {
            var filesSelected = document.getElementById("rowTypeImage").files;
            for (var i = 0; i < filesSelected.length; i++) {
                var fileReader = new FileReader();
                fileReader.onload = function (fileLoadedEvent) {
                    var test = window.btoa(fileLoadedEvent.target.result);
                    vm.rowType.image = test;
                };
                fileReader.readAsBinaryString(filesSelected[i]);
            }
        });

        function onSaveSuccess(result) {
            $scope.$emit("trakeyeApp:rowTypeUpdate", result);
            vm.isSaving = false;

            var pageValue = sessionStorage.getItem("prevPage");
            $state.go("row-type");
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        vm.addmore = function (index) {


            //vm.items.push({name: '',attributeDataType:{}, value:''});
            vm.items.splice((index + 1), 0, {
                name: "",
                attributeDataType: {},
                value: "",
            });

        };

        vm.remove = function (index) {
            vm.items.splice(index, 1);


        };

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.colorcode = function (color) {
            vm.rowType.colorcode = color;
        }

    }
})();
