(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('tr-vendor', {
                parent: 'app',
                url: '/tr-vendor',
                data: {
                    authorities: ['PROJECT_SIDEBAR'],
                    pageTitle: 'trakeyeApp.vendor.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-vendor/tr-vendors.html',
                        controller: 'TrVendorController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'last_modified_date,desc',
                        squash: true
                    },
                    search: null,
                    projectId: null,
                    routeId : null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            projectId: $stateParams.projectId,
                            routeId :  $stateParams.routeId,
                            rangeOption : $stateParams.rangeOption,
                            fromTime : $stateParams.fromTime ,
                            toTime : $stateParams.toTime
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trVendor');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('tr-vendor-detail', {
                parent: 'tr-vendor',
                url: '/tr-vendor/{id}',
                data: {
                    authorities: ['PROJECT_DETAIL'],
                    pageTitle: 'trakeyeApp.vendor.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-vendor/tr-vendor-detail.html',
                        controller: 'TrVendorDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trVendor');
                        return $translate.refresh();
                    }],
                   /*  entity: ['$stateParams', 'TrVendor', function ($stateParams, TrVendor) {
                        return TrVendor.get({ id: $stateParams.id }).$promise;
                    }], */
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'tr-vendor',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('tr-vendor.new', {
                parent: 'tr-vendor',
                url: '/create/{lat}/{lng}/{assetdetails}/{desc}/{routeId}/{sectionId}',
                data: {
                    authorities: ['PROJECT_CREATE']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-vendor/tr-vendor-dialog.html',
                        controller: 'TrVendorDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            description: $stateParams.desc,
                            createDate: null,
                            updateDate: null,
                            pinLat: parseFloat($stateParams.lat),
                            pinLong: parseFloat($stateParams.lng),
                            address: null,
                            escalated: null,
                            status: null,
                            id: null,
                            vendorImages: []
                            // ,
                            // asset: {name: $stateParams.assetdetails}
                        };
                    }],

                    route : ['$stateParams', 'TrRoute', function ($stateParams, TrRoute) {

                        if($stateParams.routeId){
                            return TrRoute.get({ id: $stateParams.routeId }).$promise;
                        }else{
                            return null
                        }
                        
                    }],

                    section : ['$stateParams', 'TrSection', function ($stateParams, TrSection) {

                        if($stateParams.sectionId){
                            return TrSection.get({ id: $stateParams.sectionId }).$promise;
                        }else{
                            return null
                        }
                        
                    }]
                }
            })

        .state('tr-vendor.import', {
                parent: 'tr-vendor',
                url: '/import',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-vendor/tr-vendor-import.html',
                        controller: 'TrVendorImportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            description: null,
                            createDate: null,
                            updateDate: null,
                            pinLat: null,
                            pinLong: null,
                            address: null,
                            escalated: null,
                            status: null,
                            id: null,
                            vendorImages: []
                        };
                    }
                }

            })
            .state('tr-vendor.edit', {
                parent: 'tr-vendor',
                url: '/Edit/{id}/{routeId}/{sectionId}',
                data: {
                    authorities: ['PROJECT_EDIT'],
                    pageTitle: 'trakeyeApp.vendor.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-vendor/tr-vendor-dialog.html',
                        controller: 'TrVendorDialogController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {

                    entity: ['$stateParams', 'TrVendor', function ($stateParams, TrVendor) {
                        return TrVendor.get({ id: $stateParams.id }).$promise;
                    }],


                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'tr-vendor',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],

                    route : ['$stateParams', 'TrRoute', function ($stateParams, TrRoute) {

                        if($stateParams.routeId){
                            return TrRoute.get({ id: $stateParams.routeId }).$promise;
                        }else{
                            return null
                        }
                        
                    }],

                    section : ['$stateParams', 'TrSection', function ($stateParams, TrSection) {

                        if($stateParams.sectionId){
                            return TrSection.get({ id: $stateParams.sectionId }).$promise;
                        }else{
                            return null
                        }
                        
                    }]
                }
            })
    }
})();