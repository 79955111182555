(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('tr-task', {
                parent: 'app',
                url: '/tr-task',
                data: {
                    authorities: ['TASK_SIDEBAR'],                    
                    pageTitle: 'trakeyeApp.task.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-task/tr-tasks.html',
                        controller: 'TrTaskController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'last_modified_date,desc',
                        squash: true
                    },
                    search: null,
                    projectId: null,
                    routeId : null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            projectId: $stateParams.projectId,
                            routeId :  $stateParams.routeId,
                            rangeOption : $stateParams.rangeOption,
                            fromTime : $stateParams.fromTime ,
                            toTime : $stateParams.toTime
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trTask');
                        $translatePartialLoader.addPart('taskStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('tr-task-detail', {
                parent: 'tr-task',
                url: '/tr-task/{id}/{assignee}',
                data: {
                    authorities: ['TASK_DETAIL'],
                    pageTitle: 'trakeyeApp.task.detail.title'
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'last_modified_date,desc',
                        squash: true
                    },
                    search: null,
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-task/tr-task-detail.html',
                        controller: 'TrTaskDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trTask');
                        $translatePartialLoader.addPart('taskStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'TrTask', function ($stateParams, TrTask) {
                        return TrTask.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'tr-task',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],

                    assignee : ['$stateParams', function ($stateParams) {
                        return  $stateParams.assignee
                    }]
                }
            })

            .state('tr-task.priority', {
                parent: 'tr-task',
                url: '/tr-tasks/priority/{priority}/{geofenceId}',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER'],
                    pageTitle: 'trakeyeApp.task.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-task/tr-tasks.html',
                        controller: 'TrTaskController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'last_modified_date,desc',
                        squash: true
                    },
                    search: null

                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            priority: $stateParams.priority,
                            geofenceId : $stateParams.geofenceId
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trTask');
                        $translatePartialLoader.addPart('taskStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('tr-task.attendance', {
                parent: 'tr-task',
                url: '/tr-tasks/attendance/{login}/{fromTime}/{toTime}/{taskStatus}',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_VENDOR', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER'],
                    pageTitle: 'trakeyeApp.task.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-task/tr-tasks.html',
                        controller: 'TrTaskController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'last_modified_date,desc',
                        squash: true
                    },
                    search: null

                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            login: $stateParams.login,
                            fromTime: $stateParams.fromTime,
                            toTime: $stateParams.toTime,
                            taskStatus: $stateParams.taskStatus
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trTask');
                        $translatePartialLoader.addPart('taskStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('tr-task.new', {
                parent: 'tr-task',
                url: '/create/{lat}/{lng}/{assetdetails}/{desc}/{routeId}/{sectionId}',
                data: {
                    authorities: ['TASK_CREATE']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-task/tr-task-dialog.html',
                        controller: 'TrTaskDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            description: $stateParams.desc,
                            createDate: null,
                            updateDate: null,
                            pinLat: parseFloat($stateParams.lat),
                            pinLong: parseFloat($stateParams.lng),
                            address: null,
                            escalated: null,
                            status: null,
                            id: null,
                            taskImages: []
                            // ,
                            // asset: {name: $stateParams.assetdetails}
                        };
                    }],
                    section : ['$stateParams', 'TrSection', function ($stateParams, TrSection) {

                        if($stateParams.sectionId){
                            return TrSection.get({ id: $stateParams.sectionId }).$promise;
                        }else{
                            return null
                        }
                        
                    }]
                }
            })

        .state('tr-task.workflow', {
            url: '/work-flow',
            data: {
                authorities: ['ROLE_USER_ADMIN', 'ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tr-task/tr-task-work-flow.html',
                    controller: 'TrTaskWorkFlowController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        description: $stateParams.desc,
                        createDate: null,
                        updateDate: null,
                        pinLat: parseFloat($stateParams.lat),
                        pinLong: parseFloat($stateParams.lng),
                        address: null,
                        escalated: null,
                        status: null,
                        id: null,
                        taskImages: []
                    };
                }]
            }
        })

        .state('tr-task.import', {
                parent: 'tr-task',
                url: '/import',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-task/tr-task-import.html',
                        controller: 'TrTaskImportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            description: null,
                            createDate: null,
                            updateDate: null,
                            pinLat: null,
                            pinLong: null,
                            address: null,
                            escalated: null,
                            status: null,
                            id: null,
                            taskImages: []
                        };
                    }
                }

            })
            .state('tr-task.edit', {
                parent: 'tr-task',
                url: '/Edit/{id}/{routeId}/{sectionId}',
                data: {
                    authorities: ['TASK_EDIT'],
                    pageTitle: 'trakeyeApp.task.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-task/tr-task-dialog.html',
                        controller: 'TrTaskDialogController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {

                    entity: ['$stateParams', 'TrTask', function ($stateParams, TrTask) {
                        return TrTask.get({ id: $stateParams.id }).$promise;
                    }],


                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'tr-task',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],

                    route : ['$stateParams', 'TrRoute', function ($stateParams, TrRoute) {

                        if($stateParams.routeId){
                            return TrRoute.get({ id: $stateParams.routeId }).$promise;
                        }else{
                            return null
                        }
                        
                    }],

                    section : ['$stateParams', 'TrSection', function ($stateParams, TrSection) {

                        if($stateParams.sectionId){
                            return TrSection.get({ id: $stateParams.sectionId }).$promise;
                        }else{
                            return null
                        }
                        
                    }]
                }
            })

            .state('tr-task.delete', {
                parent: 'tr-task',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/tr-task/tr-task-delete-dialog.html',
                        controller: 'TrTaskDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['TrTask', function (TrTask) {
                                return TrTask.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('tr-task', null, { reload: 'tr-task' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('tr-task.fibercore', {
                parent: 'tr-task',
                url: '/fibercore',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-task/tr-task-fiber-core.html',
                        controller: 'TrTaskFiberCoreController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            description: null,
                            createDate: null,
                            updateDate: null,
                            pinLat: null,
                            pinLong: null,
                            address: null,
                            escalated: null,
                            status: null,
                            id: null,
                            taskImages: []
                        };
                    }
                }

            })

            .state('show-hide-task', {
                parent: 'report',
                url: '/show-hide-task',
                data: {
                    authorities: ['ROLE_USER_ADMIN'],
                    pageTitle: 'trakeyeApp.report.generic-report'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-task/show-hide-task.html',
                        controller: 'ShowHideTaskController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reports');
                        $translatePartialLoader.addPart('logSource');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })



            .state('task-log', {
                parent: 'app',
                url: '/task-logs',
                data: {
                    authorities: ['DAILYLOG_SIDEBAR'],
                    pageTitle: 'dailyTaskLogs.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/task-log/task-log.html',
                        controller: 'TaskLogController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'last_modified_date,desc',
                        squash: true
                    },
                    search: null,
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('task-log.dialog', {
                parent: 'task-log',
                url: '/{id}/{taskId}/{sectionId}/{routeId}/{str}',
                data: {
                    authorities: ['DAILYLOG_CREATE','DAILYLOG_EDIT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/task-log/task-log.dialog.html',
                        controller: 'TaskLogDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    task : ['$stateParams','TrTask', function ($stateParams,TrTask) {
                        if($stateParams.taskId){ 
                            return TrTask.get({ id: $stateParams.taskId }).$promise;
                        }else{
                            return null
                        }
                    }],

                    taskLog : ['$stateParams','DailyLogs', function ($stateParams,DailyLogs) {

                            if($stateParams.id){
                                return DailyLogs.get({id:$stateParams.id}).$promise;
                            }else{
                                return {
                                    id : null
                                }
                            }
                            
                    }] ,

                    section : ['$stateParams','TrSection', function ($stateParams,TrSection) {
                        if($stateParams.sectionId){
                            return TrSection.get({ id: $stateParams.sectionId }).$promise;
                        }else{
                            return null
                        }
                    }],

                    route : ['$stateParams', 'TrRoute', function ($stateParams, TrRoute) {

                        if($stateParams.routeId){
                            return TrRoute.get({ id: $stateParams.routeId }).$promise;
                        }else{
                            return null
                        }
                        
                    }],

                    str : ['$stateParams', function ($stateParams) {

                        if($stateParams.str){
                            return $stateParams.str
                        }else{
                            return null
                        }
                        
                    }],

                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trTask');
                        return $translate.refresh();
                    }]
                    /* entity: ['$stateParams', 'DailyLogs', function ($stateParams, DailyLogs) {
                        return DailyLogs.get($stateParams.id).$promise;
                    }],  */

                }
            })

            .state('task-log.delete', {
                parent: 'task-log',
                url: '/{id}',
                data: {
                    authorities: ['DAILYLOG_DELETE']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/task-log/task-log.dialog.html',
                        controller: 'TaskLogDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                    taskLog : ['$stateParams','DailyLogs', function ($stateParams,DailyLogs) {

                            if($stateParams.id){
                                return DailyLogs.delete({id:$stateParams.id}).$promise;
                            }
                            
                    }] ,
                }
            })
            
    }
})();