(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("SectionTypeDialogController", SectionTypeDialogController);

    SectionTypeDialogController.$inject = [
        "$timeout",
        "$scope",
        "$stateParams",
        "$state",
        "entity",
        "SectionType",
        "User",
        "SectionTypeSearch",
        "AllCustomSectionTypeAttribute",
    ];

    
    function SectionTypeDialogController(
        $timeout,
        $scope,
        $stateParams,
        $state,
        entity,
        SectionType,
        User,
        SectionTypeSearch,
        AllCustomSectionTypeAttribute
    ) {
        var vm = this;
        //sectiontype attribute types
        $scope.attributetypes = ["INTEGER", "FLOAT", "STRING", "BOOLEAN"];
        //end
        vm.sectionType = entity;
        vm.sectionTypeImageName = sectionTypeImageName;
        vm.decodeImage = decodeImage;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.customSectionTypeAttributes = loadPage();
        vm.customAttributesMap = {};
        $scope.allimages = false;
        $scope.toggle = function () {
            $scope.allimages = !$scope.allimages;
        };
        vm.save = save;
        $(":file").filestyle({ buttonBefore: true });
        $(":file").filestyle("buttonText", "Browse File");

        

        vm.checkNumber;

        
        
       

        function sectionTypeImageName(image) {
            vm.sectionType.image = image;
        }
        
       
        function decodeImage(img) {
            return window.atob(img);
        }

        function loadPage() {
            AllCustomSectionTypeAttribute.getAll(function (response) {
                vm.customSectionTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    vm.customAttributesMap[arrayItem.name] =
                        arrayItem.customSectionTypeAttributeValues;
                });
            });
        }

        //      vm.users = User.query();
        vm.items = [{ name: "", attributeDataType: {}, value: "" }];

        if (
            vm.sectionType.sectionTypeAttribute != null &&
            vm.sectionType.sectionTypeAttribute.length != 0
        ) {
            vm.items = vm.sectionType.sectionTypeAttribute;
        }
        var tempItems = [];
        vm.items.forEach(function (value) {
            if (value.sectionCategory == null) {
                value.sectionCategory = "DEFAULT";
            }
            tempItems.push(value);
        });

        vm.items = tempItems;
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }
        function save() {
            vm.isSaving = true;
            if (vm.sectionType.id !== null) {
                vm.sectionType.sectionTypeAttribute = [];
                for (var i = 0; i < vm.items.length; i++) {
                    var attributes = { name: vm.items[i] };
                    vm.sectionType.sectionTypeAttribute.push({
                        sectionCategory: vm.items[i].sectionCategory,
                        id: vm.items[i].id,
                        mandatory: vm.items[i].mandatory,
                        name: vm.items[i].name,
                        attributeDataType: vm.items[i].attributeDataType,
                        defaultValue: vm.items[i].value,
                        sectionTypeAttribOrder: vm.items[i].sectionTypeAttribOrder
                    });
                }
                SectionType.update(vm.sectionType, onSaveSuccess, onSaveError);
            } else {
                vm.sectionType.sectionTypeAttribute = [];

                for (var i = 0; i < vm.items.length; i++) {
                    var attributes = { name: vm.items[i] };
                    vm.sectionType.sectionTypeAttribute.push({
                        sectionCategory: vm.items[i].sectionCategory,
                        id: vm.items[i].id,
                        mandatory: vm.items[i].mandatory,
                        name: vm.items[i].name,
                        attributeDataType: vm.items[i].attributeDataType,
                        defaultValue: vm.items[i].value,
                        sectionTypeAttribOrder: vm.items[i].sectionTypeAttribOrder
                    });
                }
                SectionType.save(vm.sectionType, onSaveSuccess, onSaveError);
            }
        }

        $("#sectionTypeImage").change(function () {
            var filesSelected = document.getElementById("sectionTypeImage").files;
            for (var i = 0; i < filesSelected.length; i++) {
                var fileReader = new FileReader();
                fileReader.onload = function (fileLoadedEvent) {
                    var test = window.btoa(fileLoadedEvent.target.result);
                    vm.sectionType.image = test;
                };
                fileReader.readAsBinaryString(filesSelected[i]);
            }
        });

        function onSaveSuccess(result) {
            $scope.$emit("trakeyeApp:sectionTypeUpdate", result);
            vm.isSaving = false;

            var pageValue = sessionStorage.getItem("prevPage");
            $state.go("section-type");
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        vm.addmore = function (index) {

            
            //vm.items.push({name: '',attributeDataType:{}, value:''});
            vm.items.splice((index+1), 0, {
                name: "",
                attributeDataType: {},
                value: "",
            });
           
        };

        vm.remove = function (index) {
            vm.items.splice(index, 1);
           
           
        };

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

       
    }
})();
