(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrSection', TrSection);

	TrSection.$inject = ['$resource', 'DateUtils'];

	function TrSection($resource, DateUtils) {
		var resourceUrl = 'api/tr-sections/:id';

		return $resource(resourceUrl, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);

					}
					return data;
				}
			},
			'update': {
				method: 'PUT'
			}
		});
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionListByRouteId', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-sections-by-routeId/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionListIdByRouteId', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-sections-list-by-routeId/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionListIdName', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (callback) {
			$http.get('api/tr-sections-list').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionGeofence', TrSectionGeofence);

	TrSectionGeofence.$inject = ['$resource'];

	function TrSectionGeofence($resource) {
		var service = $resource('/api/tr-sections/geofence/:geofenceId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionCustomTime', TrSectionCustomTime);

	TrSectionCustomTime.$inject = ['$resource'];

	function TrSectionCustomTime($resource) {
		var service = $resource('/api/tr-sections-custom/:fromDate/:toDate', {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionGeofenceCustomTime', TrSectionGeofenceCustomTime);

	TrSectionGeofenceCustomTime.$inject = ['$resource'];

	function TrSectionGeofenceCustomTime($resource) {
		var service = $resource('/api/tr-sections-custom/geofence/:geofenceId/:fromDate/:toDate', {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrSectionCheckAsset', TrSectionCheckAsset);

	TrSectionCheckAsset.$inject = ['$resource', 'DateUtils'];

	function TrSectionCheckAsset($resource, DateUtils) {
		var resourceUrl = 'api/assets/search/:name';

		return $resource(resourceUrl, {}, {
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
		});
	}
})();


(function () {
	'use strict';
	angular.module('trakeyeApp').factory('SectionsForAsset', SectionsForAsset);

	SectionsForAsset.$inject = ['$resource'];

	function SectionsForAsset($resource) {
		var resourceUrl = 'api/sections-for-asset/:assetId';

		return $resource(resourceUrl, {}, {
			'query': {
				method: 'GET',
				isArray: true
			}
		});
	}
})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-sections/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('SectionPathById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-sections-path/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('getSectionImage', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/trakeyesectionimages/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('getNearbyFixed', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, data, callback) {
			$http.post('api/assets/nearby-fixed/' + id, data).then(function (response) {
				callback(response.data);
			});
		};
		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionAssetsById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-sections-assets/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('SectionAttributeMappingId', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/custom-section-type-mapper/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

/*
(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionById', TrSectionById);

	TrSectionById.$inject = [ '$resource' ];

	function TrSectionById($resource) {
		var service = $resource('/api/tr-sections/:id', {}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}

})();*/

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionCountByGroup', ['$http', function ($http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/sectioncountbygroup').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('DownloadSectionReports', ['$http', function ($http) {

		var service = {};
		service.download = function (callback) {
			$http.get('api/download-sections').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionCountByStatus', ['$http', function ($http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/sectioncountbystatus').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionPriority', TrSectionPriority);

	TrSectionPriority.$inject = ['$resource'];

	function TrSectionPriority($resource) {
		var service = $resource('/api/tr-sections/priority/:priority/:geofenceId', {
			priority: '@priorirty'
		}, { geofenceId: '@geofenceId' }, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionAttendance', TrSectionAttendance);

	TrSectionAttendance.$inject = ['$resource'];

	function TrSectionAttendance($resource) {
		var service = $resource('/api/tr-sections/attendance/:login/:fromTime/:toTime/:sectionStatus',
			{}, {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionSearch', TrSectionSearch);

	TrSectionSearch.$inject = ['$resource'];

	function TrSectionSearch($resource) {
		var service = $resource('/api/tr-sections/searchvalue/:search', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionSearchCustom', TrSectionSearchCustom);

	TrSectionSearchCustom.$inject = ['$resource'];

	function TrSectionSearchCustom($resource) {
		var service = $resource('/api/tr-sections-custom/searchvalue/:search/:fromDate/:toDate', {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionGeofenceSearch', TrSectionGeofenceSearch);

	TrSectionGeofenceSearch.$inject = ['$resource'];

	function TrSectionGeofenceSearch($resource) {
		var service = $resource('/api/tr-sections/geofence-searchvalue/:geofenceId/:search', {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionGeofenceSearchCustom', TrSectionGeofenceSearchCustom);

	TrSectionGeofenceSearchCustom.$inject = ['$resource'];

	function TrSectionGeofenceSearchCustom($resource) {
		var service = $resource('/api/tr-sections-custom/geofence-searchvalue/:geofenceId/:search/:fromDate/:toDate', {}, {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrSectionGroup', TrSectionGroup);

	TrSectionGroup.$inject = ['$resource', 'DateUtils'];

	function TrSectionGroup($resource, DateUtils) {
		var resourceUrl = 'api/tr-sections-group';

		return $resource(resourceUrl, {
			'query': {
				method: 'GET',
				isArray: true
			}
		});
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionGroupSearch', TrSectionGroupSearch);

	TrSectionGroupSearch.$inject = ['$resource'];

	function TrSectionGroupSearch($resource) {
		var service = $resource('/api/tr-sections-group/searchvalue/:search', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionGroupPrioritySearch', TrSectionGroupPrioritySearch);

	TrSectionGroupPrioritySearch.$inject = ['$resource'];

	function TrSectionGroupPrioritySearch($resource) {
		var service = $resource('/api/tr-sections-group/prioritysearch/:priority/:searchtext', {
			priority: '@priorirty',
			searchtext: '@searchtext'
		}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionGroupPriority', TrSectionGroupPriority);

	TrSectionGroupPriority.$inject = ['$resource'];

	function TrSectionGroupPriority($resource) {
		var service = $resource('/api/tr-sections-group/priority/:priority', {
			priority: '@priorirty'
		}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionImages', TrSectionImages);

	TrSectionImages.$inject = ['$resource'];

	function TrSectionImages($resource) {
		var service = $resource('/api/section-images/:sectionId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionsForType', TrSectionsForType);

	TrSectionsForType.$inject = ['$resource'];

	function TrSectionsForType($resource) {
		var service = $resource('/api/trsectionsbytype/:sectionTypeId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: false
			},
		});

		return service;
	}

})();
(function () {
	'use strict';

	angular.module('trakeyeApp').factory('NearByBGOH', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAll = function (data, success, error) {
			$http.post('api/tr-sections/nearby-bgoh-assets', data).then(function (response) {
				success(response.data);
			}, function (response) {
				error(response.status);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionRecordings', TrSectionRecordings);

	TrSectionRecordings.$inject = ['$resource'];

	function TrSectionRecordings($resource) {
		var service = $resource('/api/section-recordings/:sectionId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('AllSectionTypes', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/section-alltypes').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionPrioritySearch', TrSectionPrioritySearch);

	TrSectionPrioritySearch.$inject = ['$resource'];

	function TrSectionPrioritySearch($resource) {
		var service = $resource('/api/tr-sections/prioritysearch/:priority/:searchtext', {
			priority: '@priorirty',
			searchtext: '@searchtext'
		}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrSectionUpdateGroup', TrSectionUpdateGroup);

	TrSectionUpdateGroup.$inject = ['$resource'];

	function TrSectionUpdateGroup($resource) {
		var resourceUrl = 'api/tr-sections/groups';

		return $resource(resourceUrl, {}, {
			'update': {
				method: 'PUT'
			}
		});
	}
})();
(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrSectionBulkDelete', TrSectionBulkDelete);

	TrSectionBulkDelete.$inject = ['$resource'];

	function TrSectionBulkDelete($resource) {
		var resourceUrl = 'api/tr-sections/bulk/delete';

		return $resource(resourceUrl, {}, {
			'save': {
				method: 'POST'
			}
		});
	}
})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionUser', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.gettrsectionsuser = function (callback) {
			$http.get('api/tr-sections/live-logs').then(function (response) {
				callback(response.data);
			});
		};

		service.gettrsectionsuserbyid = function (id, callback) {
			$http.get('api/tr-sections/live-logs/' + id).then(function (response) {
				callback(response.data);
			});
		};

		service.searchtrsectionsuserbyvalue = function (id, callback) {
			$http.get('api/tr-sections/search/' + id).then(function (response) {
				callback(response.data);
			});
		};
		service.gettrsectionsanduserbypriority = function (priority, callback) {
			$http.get('api/tr-sections/live-logs/priority/' + priority).then(function (response) {
				callback(response.data);
			});
		};

		service.getAgents = function (callback) {
			$http.get('api/dashboard/agentdashboard').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionEdit', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getalltrsections = function (callback) {
			$http.get('api/tr-allsections').then(function (response) {
				callback(response.data);
			});
		};

		service.edittrsection = function (data, callback) {
			$http.post('api/tr-sectionedit/' + data.userId + '/' + data.sectionId, {

			}).then(function (response) {

				callback(response, response.headers);
			}, function (error) {

				callback(error, error.headers);
			});

			/*
			 * $http.post('api/tr-sectionedit/'+data.userId+'/'+data.sectionId).then(function(response){
			 * callback(response.data); });
			 */
		};

		return service;
	}]);
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrSectionUpload', TrSectionUpload);

	TrSectionUpload.$inject = ['$http'];

	function TrSectionUpload($http) {

		var service = {};
		service.trsectionupload = function (data, callback) {

			$http.post('api/sectionimport', data, {
				transformRequest: angular.identity,
				headers: {
					'Content-Type': undefined
				}
			}).then(function (response) {

				callback(response, response.headers);
			}, function (error) {
				callback(error, error.headers);
			});
		};

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrGroupSectionSearchForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.searchtrsectionformap = function (search, callback) {
			$http.get('api/tr-groupsectionsformap/searchvalue/' + search).then(function (response) {
				callback(response.data);
			});
		};
		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrGroupSectionForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAllSections = function (callback) {
			$http.get('api/tr-groupsectionsformap').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionSearchForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.searchtrsectionformap = function (search, callback) {
			$http.get('api/tr-sectionsformap/searchvalue/' + search).then(function (response) {
				callback(response.data);
			});
		};

		service.prioritysearchtrsectionformap = function (priority, callback) {
			$http.get('api/tr-sectionsformap/priority/' + priority).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();


(function () {
	'use strict';

	angular
		.module('trakeyeApp')
		.factory('TrSectionLastLocation', ['$rootScope', '$http', function ($rootScope, $http) {
			var service = {};

			service.getlastlogs = function (callback) {
				$http.get('api/location-logs/lastuserpath').then(function (response) {
					callback(response.data);
				});
			};
			return service;
		}]);



})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('SectionsForMapByAdmin', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.sectionsformapbyadmin = function (adminid, callback) {
			$http.get('api/tr-sectionsformap/' + adminid).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrSectionForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAllSections = function (data, callback) {
			// $http.get('api/tr-sectionsformap').then(function (response) {
			// 	callback(response.data);
			// });
			$http.post('api/tr-sectionsformap', data).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('ShowSectionById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.update = function (sectionid, callback) {
			$http.put('api/show-section/' + sectionid)
				.success(function (data, status, headers, config) {
					callback(status);
				})
				.error(function (data, status, header, config) {
					callback(status);
				});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('HideSectionById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.update = function (sectionid, callback) {
			$http.put('api/hide-section/' + sectionid)
				.success(function (data, status, headers, config) {
					callback(status);
				})
				.error(function (data, status, header, config) {
					callback(status);
				});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('Inventory', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};

		//GET
		service.materialTxn = function (id, callback) {
			$http.get('api/rollout/mtrl-tx/' + id).then(function (response) {
				callback(response.data);
			})
		};

		service.getMaterialTxn = function (id, materialId, callback) {
			$http.get('api/rollout/mtrl-tx/' + id + '/' + materialId).then(function (response) {
				callback(response.data);
			})
		};

		service.resourceTxn = function (id, callback) {
			$http.get('api/rollout/res-tx/' + id).then(function (response) {
				callback(response.data);
			})
		};

		service.getResourceTxn = function (id, resourceId, callback) {
			$http.get('api/rollout/res-tx/' + id + '/' + resourceId).then(function (response) {
				callback(response.data);
			})
		};

		//POST
		service.postMaterialTxn = function (payload, callback) {
			$http.post('api/rollout/log-mtrl-tx', payload).then(function (response) {
				callback(response.data);
			})
		};

		service.postBulkMaterialTxn = function (payload, callback) {
			$http.post('api/rollout/bulk-log-mtrl-tx', payload).then(function (response) {
				callback(response.data);
			})
		};

		service.postResourceTxn = function (payload, callback) {
			$http.post('api/rollout/log-res-tx', payload).then(function (response) {
				callback(response.data);
			})
		};

		service.postBulkResourceTxn = function (payload, callback) {
			$http.post('api/rollout/bulk-log-res-tx', payload).then(function (response) {
				callback(response.data);
			})
		};

		//PUT

		service.putMaterialTxn = function (payload, callback) {
			$http.put('api/rollout/log-mtrl-tx', payload).then(function (response) {
				callback(response.data);
			})
		};

		service.putResourceTxn = function (payload, callback) {
			$http.put('api/rollout/log-res-tx', payload).then(function (response) {
				callback(response.data);
			})
		};

		//GET Task by Section Id
		service.taskBySection = function (id, callback) {
			$http.get('api/tr-tasks-by-section-list/' + id).then(function (response) {
				callback(response.data);
			})
		};
		service.taskPathByID = function (id, callback) {
			$http.get('api/tr-tasks-path/' + id).then(function (response) {
				callback(response.data);
			});
		};
		return service;
	}]);
})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('Summary', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};

		//GET
		service.resTx = function (id, callback) {
			$http.get('api/rollout/res-tx-summary/' + id).then(function (response) {
				callback(response.data);
			})
		};

		service.mtrlTx = function (id, callback) {
			$http.get('api/rollout/mtrl-tx-summary/' + id).then(function (response) {
				callback(response.data);
			})
		};

		service.dailyLogsTx = function (id, callback) {
			$http.get('api/rollout/daily-task-logs-summary/' + id).then(function (response) {
				callback(response.data);
			})
		};


		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TaskSectionById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-sections-path/' + id).then(function (response) {
				callback(response.data);
			});
		};

		service.postAutoGenerateTasks = function (payload, callback) {
			$http.post('api/tr-sections/auto-generate-tasks', payload).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();