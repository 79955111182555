(function () {
    "use strict";

    angular.module("trakeyeApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("report", {
                abstract: true,
                parent: "app",
            })
            .state("generic-report", {
                parent: "report",
                url: "/generic-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.generic-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/report.html",
                        controller: "ReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("geofence-report", {
                parent: "report",
                url: "/geofence-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_VENDOR",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.geofence-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/geofence-report.html",
                        controller: "GeofenceReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })
            /*   
               .state('asset-type-site-report', {
                   parent: 'report',
                   url: '/asset-type-site-report',
                   data: {
                       authorities: ['ROLE_USER', 'ROLE_USER_ADMIN'],
                       pageTitle: 'trakeyeApp.report.asset-type-site-report'
                   },
                   views: {
                       'content@': {
                           templateUrl: 'app/report/asset-type-site-report.html',
                           controller: 'AssetTypeSiteReportController',
                           controllerAs: 'vm'
                       }
                   },
                   resolve: {
                       translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                           $translatePartialLoader.addPart('reports');
                           $translatePartialLoader.addPart('logSource');
                           $translatePartialLoader.addPart('global');
                           return $translate.refresh();
                   }]
                   }
               })*/

            .state("case-report", {
                parent: "report",
                url: "/case-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.generic-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/case-report.html",
                        controller: "CaseReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state("case-type-report", {
                parent: "report",
                url: "/case-type-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_VENDOR",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.generic-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/case-type-report.html",
                        controller: "CaseReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("entity-dump", {
                parent: "report",
                url: "/entity-dump",
                data: {
                    pageTitle: "trakeyeApp.report.entity-dump",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/entity-dump.html",
                        controller: "EntityDumpController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state('entity-dump.new', {
                parent: 'entity-dump',
                url: '/create',
                views: {
                    'content@': {
                        templateUrl: 'app/report/entity-dump-dialog.html',
                        controller: 'EntityDumpDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("asset-health-report", {
                parent: "report",
                url: "/asset-health",
                data: {
                    pageTitle: "trakeyeApp.report.asset-health-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/asset-health-report.html",
                        controller: "AssetHealthController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("asset-health-status-report", {
                parent: "report",
                url: "/asset-health-status",
                data: {
                    pageTitle: "trakeyeApp.report.asset-health-status-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/asset-health-status-report.html",
                        controller: "AssetHealthStatusController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("asset-geofence-mapping", {
                parent: "report",
                url: "/asset-geofence-mapping",
                data: {
                    pageTitle: "trakeyeApp.report.asset-geofence-mapping",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/asset-geofence-mapping.html",
                        controller: "AssetGeofenceMappingController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("geofence-dump", {
                parent: "report",
                url: "/geofence-dump",
                data: {
                    authorities: ["ROLE_USER_ADMIN"],
                    pageTitle: "trakeyeApp.report.generic-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/geofence-dump.html",
                        controller: "GeofenceDumpController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("splicing-report", {
                parent: "report",
                url: "/splicing-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_VENDOR",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.generic-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/splicing-report.html",
                        controller: "SplicingReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("user-agent-report", {
                parent: "report",
                url: "/user-agent-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_VENDOR",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.user-agent-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/user-agent-report.html",
                        controller: "UserAgentReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("fiber-status-report", {
                parent: "report",
                url: "/fiber-status-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.fiber-status-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/fiber-status-report.html",
                        controller: "FiberStatusReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("site-status-report", {
                parent: "report",
                url: "/site-status-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.site-status-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/site-status-report.html",
                        controller: "SiteStatusReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("case-aging-report", {
                parent: "report",
                url: "/case-aging-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_VENDOR",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.generic-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/case-aging-report.html",
                        controller: "CaseAgingReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            $translatePartialLoader.addPart("caseAgingReport");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("distance-report", {
                parent: "report",
                url: "/distance-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.distance-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/distance-report.html",
                        controller: "DistanceReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("battery-report", {
                parent: "report",
                url: "/battery-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.battery-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/battery-report.html",
                        controller: "ReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state("distance-travelled", {
                parent: "report",
                url: "/distance-travelled",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.distance-travelled",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/distance-travelled.html",
                        controller: "ReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state("asset-report", {
                parent: "report",
                url: "/asset-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.asset-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/asset-report.html",
                        controller: "AssetReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("asset-case-report", {
                parent: "report",
                url: "/asset-case-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.asset-case-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/asset-case-report.html",
                        controller: "AssetCaseReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("case-tat-report", {
                parent: "report",
                url: "/case-tat-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.tat.tat-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/case-tat-report.html",
                        controller: "CaseTatReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            $translatePartialLoader.addPart("caseTatReport");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("team-report", {
                parent: "report",
                url: "/team-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.teamreport.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/team-report.html",
                        controller: "TeamReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            $translatePartialLoader.addPart("teamReport");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("field-user-report", {
                parent: "report",
                url: "/field-user-report",
                data: {
                    authorities: ["ROLE_USER", "ROLE_USER_ADMIN"],
                    pageTitle: "trakeyeApp.report.distance-travelled",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/field-user-report.html",
                        controller: "UserAgentReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("case-user-report", {
                parent: "report",
                url: "/case-user-report",
                data: {
                    authorities: ["ROLE_USER", "ROLE_USER_ADMIN"],
                    pageTitle: "trakeyeApp.report.distance-travelled",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/case-user-report.html",
                        controller: "CaseAgingReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("user-attendance-report", {
                parent: "report",
                url: "/user-attendance-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_USER_ADMIN",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.generic-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/user-attendance-report.html",
                        controller: "UserAttendaceController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("mobile-user-attendance-report", {
                parent: "report",
                url: "/mobile-user-attendance-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_VENDOR",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.generic-report",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/report/mobile-user-attendance-report.html",
                        controller: "MobileUserAttendaceController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("gps-user-attendance-report", {
                parent: "report",
                url: "/gps-user-attendance-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_VENDOR",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.generic-report",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/report/gps-user-attendance-report.html",
                        controller: "GpsUserAttendaceController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("user-login-history-report", {
                parent: "report",
                url: "/user-login-history-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_VENDOR",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.generic-report",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/report/user-login-history-report.html",
                        controller: "UserLoginHistoryReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state("user-geofence-report", {
                parent: "report",
                url: "/user-geofence-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_VENDOR",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.generic-report",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/report/user-geofence-report.html",
                        controller: "UserGeofenceReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("vehicle-distance-report", {
                parent: "report",
                url: "/vehicle-distance-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_VENDOR",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.generic-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/vehicle-distance-report.html",
                        controller: "VehicleDistanceController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("user-stats-report", {
                parent: "report",
                url: "/user-stats-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_VENDOR",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.generic-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/user-stats-report.html",
                        controller: "UserStatsController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("cases-dump-report", {
                parent: "report",
                url: "/cases-dump-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_VENDOR",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.generic-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/cases-dump-report.html",
                        controller: "CasesDumpController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("user-reporting-report", {
                parent: "report",
                url: "/user-reporting-report",
                data: {
                    authorities: [
                        "ROLE_USER",
                        "ROLE_USER_ADMIN",
                        "ROLE_VENDOR",
                        "ROLE_HOD",
                        "ROLE_CITY_MANAGER",
                        "ROLE_CITY_PLANNER",
                        "ROLE_ASSISTANT_MANAGER",
                        "ROLE_TEAM_LEADER",
                    ],
                    pageTitle: "trakeyeApp.report.generic-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/user-reporting-report.html",
                        controller: "UserReportingController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("survey-report", {
                parent: "report",
                url: "/survey-report",
                data: {
                    pageTitle: "trakeyeApp.report.survey-report",
                },
                views: {
                    "content@": {
                        templateUrl: "app/report/survey-report.html",
                        controller: "SurveyReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state("task-log-report", {
                parent: "report",
                url: "/task-log-report",
                data: {
                    authorities: ['ENTITY_CONFIGURATION'],
                    pageTitle: "trakeyeApp.report.task-log-report",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/report/task-log-report.html",
                        controller: "TaskLogReportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("reports");
                            $translatePartialLoader.addPart("logSource");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            });
    }
})();
