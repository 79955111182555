(function () {
    'use strict';
    angular.module('trakeyeApp').factory('Asset', Asset);

    Asset.$inject = ['$resource'];

    function Asset($resource) {
        var resourceUrl = 'api/assets/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            },
            'delete': {
                method: 'DELETE'
            }
        });
    }
})();

(function () {
    "use strict";
    angular.module("trakeyeApp").factory("ChildAssetMarkers", ChildAssetMarkers);

    ChildAssetMarkers.$inject = ["$resource"];

    function ChildAssetMarkers($resource) {
        var resourceUrl = "api/linked-asset-markers/:assetId";

        return $resource(
            resourceUrl,
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
            }
        );
    }
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetByMarkerId', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (id, callback) {
            $http.get('api/asset-by-assetmarkerid/' + id).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetSiteIDs', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.getAll = function (callback) {
            $http.get('api/siteassetlistnew').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetCountByGroup', ['$http', function ($http) {

        var service = {};
        service.getAll = function (callback) {
            $http.get('api/assetcountbygroup').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetCountBySiteStatus', ['$http', function ($http) {

        var service = {};
        service.getAll = function (callback) {
            $http.get('api/assetcountbystatus').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

//(function() {
//    'use strict';
//
//    angular.module('trakeyeApp').factory('AssetCountByLinkStatus', ['$http', function($http) {
//
//        var service = {};
//        service.getAll = function(callback) {
//            $http.get('api/assetcountbylinkstatus').then(function(response) {
//                callback(response.data);
//            });
//        };
//
//        return service;
//    }]);
//})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetCountByLinkStatus', ['$http', function ($http) {

        var service = {};
        service.getAll = function (callback) {
            $http.get('api/assets/aggs/Link/Link%20Status').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';
    angular.module('trakeyeApp').factory('AssetGroup', AssetGroup);

    AssetGroup.$inject = ['$resource'];

    function AssetGroup($resource) {
        var resourceUrl = 'api/assets-group-db';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetGroupSearch', AssetGroupSearch);

    AssetGroupSearch.$inject = ['$resource'];

    function AssetGroupSearch($resource) {
        var service = $resource('/api/assets-group-db/searchvalue/:search', {
            // searchvalue : '@id'
        }, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
        });

        return service;
    }

})();

(function () {
    'use strict';
    angular.module('trakeyeApp').factory('AssetGroupWithGeofence', AssetGroupWithGeofence);

    AssetGroupWithGeofence.$inject = ['$resource'];

    function AssetGroupWithGeofence($resource) {
        var resourceUrl = 'api/assets-group-db/geofence/:geofenceId';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();

(function () {
    'use strict';
    angular.module('trakeyeApp').factory('AssetStatusListWithGeofence', AssetStatusListWithGeofence);

    AssetStatusListWithGeofence.$inject = ['$resource'];

    function AssetStatusListWithGeofence($resource) {
        var resourceUrl = 'api/asset-health-list-with-geofence/:geofenceId';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();


(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetGroupSearchWithGeofence', AssetGroupSearchWithGeofence);

    AssetGroupSearchWithGeofence.$inject = ['$resource'];

    function AssetGroupSearchWithGeofence($resource) {
        var service = $resource('/api/assets-group-db/geofence-searchvalue/:geofenceId/:search', {
            // searchvalue : '@id'
        }, {}, {}, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
        });

        return service;
    }

})();

(function () {
    'use strict';
    angular.module('trakeyeApp').factory('AssetsUpdateGroup', AssetsUpdateGroup);

    AssetsUpdateGroup.$inject = ['$resource'];

    function AssetsUpdateGroup($resource) {
        var resourceUrl = 'api/assets/groups';

        return $resource(resourceUrl, {}, {
            'update': {
                method: 'PUT'
            }
        });
    }
})();

(function () {
    'use strict';
    angular.module('trakeyeApp').factory('AssetsBulkDelete', AssetsBulkDelete);

    AssetsBulkDelete.$inject = ['$resource'];

    function AssetsBulkDelete($resource) {
        var resourceUrl = 'api/assets/bulk/delete';

        return $resource(resourceUrl, {
            'save': {
                method: 'POST'
            }
        });
    }
})();

(function () {
    'use strict';
    angular.module('trakeyeApp').factory('UpdateAssetsGroup', UpdateAssetsGroup);

    UpdateAssetsGroup.$inject = ['$resource'];

    function UpdateAssetsGroup($resource) {
        var resourceUrl = 'api/assets';

        return $resource(resourceUrl, {}, {
            'update': {
                method: 'PUT'
            }
        });
    }
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('PrivateAssetsForMapByAdmin', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.assetsformapbyadmin = function (adminid, callback) {
            $http.get('api/privateassetsformap/' + adminid).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';
    angular.module('trakeyeApp').factory('ChildAssetsType', ChildAssetsType);

    ChildAssetsType.$inject = ['$resource'];

    function ChildAssetsType($resource) {
        var resourceUrl = 'api/childassetslist/:parentId';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();

(function () {
    'use strict';
    angular.module('trakeyeApp').factory('ChildAssets', ChildAssets);

    ChildAssets.$inject = ['$resource'];

    function ChildAssets($resource) {
        var resourceUrl = 'api/childAssets/:parentId';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();

(function () {
    'use strict';
    angular.module('trakeyeApp').factory('ChildAssetsByTypeName', ChildAssetsByTypeName);

    ChildAssetsByTypeName.$inject = ['$resource'];

    function ChildAssetsByTypeName($resource) {
        var resourceUrl = 'api/childassetstypenamelist/:parentId';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetImages', AssetImages);

    AssetImages.$inject = ['$resource'];

    function AssetImages($resource) {
        var service = $resource('/api/asset-images/:assetId', {}, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
        });

        return service;
    }

})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetGroupByBufferGeo', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (minigeofenceid, callback) {
            $http.get('api/asset-groupby-buffergeofence/' + minigeofenceid).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

/* (function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetIdImageId', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (assetId,imageId, callback) {
            $http.get('api/trakeyeassetimages/' + assetId + '/'+ imageId).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})(); */

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('UsersList', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.getusers = function (callback) {
            $http.get('api/users').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetUser', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.gettrcasesuser = function (callback) {
            $http.get('api/location-logs/latest').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetSearchForMap', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.searchassetsformap = function (search, callback) {
            $http.get('api/assets/searchformap/' + search).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetGroupSearchForMap', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.searchassetsformap = function (search, callback) {
            $http.get('api/assets/searchgroupassetsformap/' + search).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetsForMap', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.assetsformap = function (callback) {
            $http.get('api/assetsformap').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('GroupAssetsForMap', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.assetsformap = function (callback) {
            $http.get('api/groupassetsformap').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('GroupFixedAssets', GroupFixedAssets);

    GroupFixedAssets.$inject = ['$resource'];

    function GroupFixedAssets($resource) {
        var service = $resource('/api/groupfixedassets', {}, {
            'query': {
                method: 'GET',
                isArray: true,
                ignoreLoadingBar: true
            },
        });

        return service;
    }

})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('GroupSpreadAssets', GroupSpreadAssets);

    GroupSpreadAssets.$inject = ['$resource'];

    function GroupSpreadAssets($resource) {
        var service = $resource('/api/groupspreadassets', {}, {
            'query': {
                method: 'GET',
                isArray: true,
                ignoreLoadingBar: true
            },
        });

        return service;
    }

})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('GroupFixedAssetsForMap', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.assetsformap = function (callback) {
            $http.get('api/groupfixedassetsformap').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('GroupSpreadAssetsForMap', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.assetsformap = function (callback) {
            $http.get('api/groupspreadassetsformap').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('GroupFixedAssetsForMap', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.assetsformap = function (callback) {
            $http.get('api/groupfixedassetsformap').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('GroupSpreadAssetsForMap', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.assetsformap = function (callback) {
            $http.get('api/groupspreadassetsformap').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetsForMapByAdmin', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.assetsformapbyadmin = function (adminid, callback) {
            $http.get('api/assetsformap/' + adminid).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetSearch', AssetSearch);

    AssetSearch.$inject = ['$resource'];

    function AssetSearch($resource) {
        var service = $resource('/api/assets/searchvalue/:search', {
            // searchvalue : '@id'
        }, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
        });

        return service;
    }

})();


(function () {
    'use strict';
    angular.module('trakeyeApp').factory('AssetUpload', AssetUpload);

    AssetUpload.$inject = ['$http'];

    function AssetUpload($http) {

        var service = {};
        service.assetupload = function (data, callback) {

            $http.post('api/assetsimport', data, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined
                }
            }).then(function (response) {
                callback(response, response.headers);
            }, function (error) {
                callback(error, error.headers);
            });
        };

        return service;
    }

})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetIDs', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.getAll = function (callback) {
            $http.get('api/assetlistnew').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('UserAccount', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (callback) {
            $http.get('api/user-auths').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

/*(function() {
    'use strict';

    angular.module('trakeyeApp').factory('SiteAssets', ['$rootScope', '$http', function($rootScope, $http) {

        var service = {};
        service.getAll = function(callback) {
            $http.get('api/siteassetdashboard').then(function(response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();*/

(function () {
    'use strict';
    angular
        .module('trakeyeApp')
        .factory('FilterAssetsForMap', FilterAssetsForMap);

    FilterAssetsForMap.$inject = ['$http', 'DateUtils'];

    function FilterAssetsForMap($http, DateUtils) {
        var service = {};

        service.loaddata = function (callback) {

            $http.get('api/filterassetsformap').then(function (response) {
                callback(response.data);
            });
        };


        return service;
    }
})();

(function () {
    'use strict';
    angular
        .module('trakeyeApp')
        .factory('AttrTypeVal', AttrTypeVal);

    AttrTypeVal.$inject = ['$http', 'DateUtils'];

    function AttrTypeVal($http, DateUtils) {
        var service = {};

        service.loaddata = function (type, attribute, callback) {

            $http.get('api/assets/attrvalue-type-attrname/' + type + '/' + attribute).then(function (response) {
                callback(response.data);
            });
        };


        return service;
    }
})();

(function () {
    'use strict';
    angular.module('trakeyeApp').factory('SiteAssets', SiteAssets);

    SiteAssets.$inject = ['$resource'];

    function SiteAssets($resource) {
        var resourceUrl = 'api/siteassetdashboard';

        return $resource(resourceUrl, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();

// (function() {
//     'use strict';

//     angular.module('trakeyeApp').factory('AssetCount', ['$rootScope', '$http', function($rootScope, $http) {

//         var service = {};
//         service.get = function(id, callback) {
//             $http.get('api/assetcountdashboard').then(function(response) {
//                 callback(response.data);
//             });
//         };

//         return service;
//     }]);
// })();

(function () {
    'use strict';
    angular
        .module('trakeyeApp')
        .factory('AssetCount', AssetCount);

    AssetCount.$inject = ['$http', 'DateUtils'];

    function AssetCount($http, DateUtils) {
        var service = {};

        service.get = function (callback) {

            $http.get('api/assetcountdashboard').then(function (response) {
                callback(response.data);
            });
        };


        return service;
    }
})();

(function () {
    'use strict';
    angular
        .module('trakeyeApp')
        .factory('AssetTypes', AssetTypes);

    AssetTypes.$inject = ['$http', 'DateUtils'];

    function AssetTypes($http, DateUtils) {
        var service = {};

        service.get = function (callback) {

            $http.get('api/assets/type/stats').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('SiteAssetById', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (id, callback) {
            $http.get('api/assets/type/' + id + '/stats').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetGroupES', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (geofenceId, lastid, callback) {
            $http.get('api/assets-group-es/' + geofenceId + "/" + lastid).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('MappedFixedAssets', [ '$http', function ($http) {

        var service = {};
        service.get = function (id,paramPage,paramSize,callback) {
            $http.get('api/assets-group-db/mapped-fixed-assets/' + id + '?page='+paramPage+'&size='+paramSize).then(function (response) {
                callback(response);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetGroupByGeofencesES', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function ( data, callback) {
            $http.post("api/assets-group-by-geofences-es", data).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('casesInMap', ['$http', function ($http) {

        var service = {};
        service.get = function ( data, callback) {
            $http.post("api/cases-for-asset-mapview", data).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AllAssetsTypes', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.getAll = function (callback) {
            $http.get('api/asset-types-by-roles').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AllCusAssetTypeAttr', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.getCusAssetTypeAttr = function (callback) {
            $http.get('api/all-custom-asset-type-attributes').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetByID', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (id, callback) {
            $http.get('api/assets/' + id).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetByName', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.check = function (name, callback) {
            $http.get('api/check-assets/' + name).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetDeleteByID', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.delete = function (id, callback) {
            $http.delete('api/assets/' + id).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('GeoAssetTypeReport', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.getAssetTypesByGeo = function (callback) {
            $http.get('api/reports/geoassettypereport').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('LinkAssets', GroupAssets);

    GroupAssets.$inject = ['$resource'];

    function GroupAssets($resource) {
        var service = $resource('/api/groupassets', {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
        });

        return service;
    }

})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('GroupAssets', GroupAssets);

    GroupAssets.$inject = ['$resource'];

    function GroupAssets($resource) {
        var service = $resource('/api/groupassets', {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
        });

        return service;
    }

})();

(function () {
    'use strict';
    angular.module('trakeyeApp').factory('AssetChildren', ['$rootScope', '$http', function ($rootScope, $http) {
        var service = {};
        service.getAssetChildren = function (login, callback) {
            var url = 'api/asset-childs/';
            if (login != null) {
                url = url + login
            }
            $http.get(url).then(function (response) {
                callback(response.data);
            });
        };
        return service;
    }]);
})();

(function () {
    'use strict';
    angular.module('trakeyeApp').factory('AssetsByGeofenceId', AssetsByGeofenceId);

    AssetsByGeofenceId.$inject = ['$resource'];

    function AssetsByGeofenceId($resource) {
        var resourceUrl = 'api/geofence-assets/:geofenceId';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();

(function () {
    'use strict';
    angular.module('trakeyeApp').factory('AssetsGeofenceMapping', AssetsGeofenceMapping);

    AssetsGeofenceMapping.$inject = ['$resource'];

    function AssetsGeofenceMapping($resource) {
        var resourceUrl = 'api/asset-geofence-mapping/:geofenceId';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();

(function () {
    'use strict';
    angular.module('trakeyeApp').factory('AssetsBulkDelete', AssetsBulkDelete);

    AssetsBulkDelete.$inject = ['$resource'];

    function AssetsBulkDelete($resource) {
        var resourceUrl = 'api/assets/bulk/delete';

        return $resource(resourceUrl, {
            'save': {
                method: 'POST'
            }
        });
    }
})();

// (function () {
//     'use strict';

//     angular.module('trakeyeApp').factory('AssetFixedGroupES', ['$rootScope', '$http', function ($rootScope, $http) {

//         var service = {};
//         service.get = function (geofenceId, lastid, callback) {
//             $http.get('api/assets-fixed-group-es/' + geofenceId + "/" + lastid).then(function (response) {
//                 callback(response.data);
//             });
//         };

//         return service;
//     }]);
// })();

// (function () {
//     'use strict';

//     angular.module('trakeyeApp').factory('AssetSpreadGroupES', ['$rootScope', '$http', function ($rootScope, $http) {

//         var service = {};
//         service.get = function (geofenceId, lastid, callback) {
//             $http.get('api/assets-spread-group-es/' + geofenceId + "/" + lastid).then(function (response) {
//                 callback(response.data);
//             });
//         };

//         return service;
//     }]);
// })();