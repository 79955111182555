(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrTaskDialogController", TrTaskDialogController);

    TrTaskDialogController.$inject = [
        "$timeout",
        "$scope",
        "$state",
        "entity",
        "TrTask",
        "AllTaskTypes",
        "AllCustomTaskTypeAttribute",
        "TaskAttributeMappingId",
        "Configs",
        "RouteTypeSearch",
        "ActivatedUserSearch",
        "$window",
        "AlertService",
        "$rootScope",
        "TaskSectionById",
        "TaskRouteById",
        "TrUserTask",
        "AllVendorIds",
        "UsersByVendorId",
        "section",
        "TaskPathById"
    ];

    function TrTaskDialogController(
        $timeout,
        $scope,
        $state,
        entity,
        TrTask,
        AllTaskTypes,
        AllCustomTaskTypeAttribute,
        TaskAttributeMappingId,
        Configs,
        RouteTypeSearch,
        ActivatedUserSearch,
        $window,
        AlertService,
        $rootScope,
        TaskSectionById,
        TaskRouteById,
        TrUserTask,
        AllVendorIds,
        UsersByVendorId,
        section,
        TaskPathById
    ) {
        var vm = this;
        vm.trTask = entity;
        vm.save = save;
        var entity_old = angular.copy(vm.trTask);
        vm.selectAttributes = selectedTaskTypeAttributes;
        vm.taskType = entity.taskType;
        vm.filterActivatedUser = filterActivatedUser;
        var usersMap = {};
        vm.findUsers = findUsers;

        var infowindow = new google.maps.InfoWindow();
        var openedInfoWindow = null;
        var btnAddSection;

        if ($state.params.routeId) {
            vm.trTask.routeId = parseInt($state.params.routeId, 10);
        }

        if ($state.params.sectionId) {
            vm.trTask.sectionId = parseInt($state.params.sectionId, 10);
            vm.trTask.routeId = null; // If section Id is available task is created using Section
        }

        vm.customAttributesMap = {};
        vm.customAttributesMapp = [];
        loadPage();
        var markersSection = []

        if(!vm.trTask.id){
            vm.trTask.status = "NEW"
        }

        Configs.get("RolloutTaskStatus", function (response) {
            vm.rolloutTaskStatus = response;
        });


        AllVendorIds.getAll(function (response) {
            if (response.length) {
                vm.vendorList = response.sort(function (a, b) {
                    var nameA = a.name.toUpperCase();
                    var nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0; // Names are equal
                });

                if(vm.trTask.id && vm.trTask.assignedUserId){
                    vm.userList = [];
                    response.forEach(function (item){
                        UsersByVendorId.get(item.id,function(response) {
                            response.forEach(function(user){
                                if(vm.trTask.assignedUserId ==  user.id){
                                    vm.trTask.assignedUserId = user.id
                                    vm.selectedVendor = item.id
                                }
                                var hasRequiredRole = user.authorities.some(function(userAuth) {
                                    return userAuth === 'ROLE_PLAN_SURVEY' || userAuth === 'ROLE_PLAN_ADMIN';
                                });
                                
                                if (hasRequiredRole) {
                                    // Push the user details to the list
                                    vm.userList.push({ name: user.login + " - " + user.authorities.join(', '), id: user.id });
                                }
                            });
                        });
                    })
                }
            }
        });

        function mapStyles() {
            var styles;
            return (styles = [
                {
                    featureType: "poi",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                } /* 
                {
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            color: "#EFEFEF",
                        },
                    ],
                }, */,
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "landscape",
                    stylers: [
                        {
                            color: "#efefef",
                        },
                    ],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
            ]);
        }

        if (vm.trTask.id != null) {
            vm.trTaskType = vm.trTask.taskType;
        }

        function loadPage() {

            vm.imageInNewTab = function (img) {
                var imgWindow = window.open();
                imgWindow.document.write(
                    '<img width="25%"   src="data:image/jpeg;base64,' +
                    img +
                    '">'
                );
                imgWindow.document.close();
            };

            var taskTypeAttributeValuesList =
                vm.trTask.taskTypeAttributeValues;

            try {
                for (var index in vm.trTask.taskType.taskTypeAttributes) {
                    if (
                        !checkIfTaskTypeExists(
                            vm.trTask.taskType.taskTypeAttributes[index],
                            vm.trTask.taskTypeAttributeValues
                        )
                    ) {
                        var taskTypeAttribute1 =
                            vm.trTask.taskType.taskTypeAttributes[index];
                        taskTypeAttributeValuesList.push({
                            taskTypeAttribute: taskTypeAttribute1,
                            attributeValue:
                                vm.trTask.taskType.taskTypeAttributes[index]
                                    .defaultValue,
                        });
                    }
                }
            } catch (e) { }

            function checkIfTaskTypeExists(taskType, taskTypeAttrVal) {
                var hasTaskType = false;
                for (var i = 0; i < taskTypeAttrVal.length; i++) {
                    var name = taskTypeAttrVal[i].taskTypeAttribute.name;
                    if (name == taskType.name) {
                        hasTaskType = true;
                        break;
                    }
                }
                return hasTaskType;
            }
            AllTaskTypes.getAll(function (response) {
                vm.taskTypes = response;
            });

            AllCustomTaskTypeAttribute.getAll(function (response) {
                //vm.customTaskTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    if (arrayItem.customTaskTypeAttributeValues != undefined) {
                        vm.customAttributesMap[arrayItem.name] =
                            arrayItem.customTaskTypeAttributeValues;
                        vm.customAttributesMap[arrayItem.name].parentId =
                            arrayItem.id;
                    }

                });
                if (vm.trTask.id) {
                    $(":file").filestyle({
                        buttonBefore: true,
                    });
                    $(":file").filestyle("buttonText", "Browse File");
                }
            });


        }
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function selectedTaskTypeAttributes() {
            vm.showFilter = true;
            if (vm.taskType) {
                vm.trTask.taskType = vm.taskType;
                if (
                    entity_old.taskType &&
                    vm.taskType.id === entity_old.taskType.id
                ) {
                    vm.trTask.taskTypeAttributeValues = [];
                    vm.trTask.taskTypeAttributeValues =
                        entity_old.taskTypeAttributeValues;
                } else {
                    vm.trTask.taskTypeAttributeValues = [];
                    $.each(
                        vm.taskType.taskTypeAttribute,
                        function (key, value) {
                            vm.trTask.taskTypeAttributeValues.push({
                                taskTypeAttribute:
                                    vm.taskType.taskTypeAttribute[key],
                            });
                        }
                    );
                }
            }
        }

        function filterActivatedUser() {
            if (vm.trTask.assignedToUser != null && vm.trTask.assignedToUser != "" && !angular.isUndefined(vm.trTask.assignedToUser)) {
                ActivatedUserSearch.query({
                    userId: vm.trTask.assignedToUser,
                }, onSuccess, onError);

                function onSuccess(data, headers) {
                    vm.activatedusers = [];
                    data.forEach(function (dataVal) {
                        vm.activatedusers.push(dataVal);
                    });
                    $scope.showResults = true;

                    angular.forEach(vm.activatedusers, function (value, key) {
                        usersMap[value.login] = value.id;
                    });
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            }
        }

        $scope.assignUser = function(user) {
            // Implement the logic to assign the selected user
            vm.trTask.assignedToUser = user
            $scope.showResults = false;
            vm.assignToModified = true
        };
    

        function save() {
            var spreadAV = vm.trTask.taskTypeAttributeValues;
            for (
                var spreadAssetIndex = 0;
                spreadAssetIndex < spreadAV.length;
                spreadAssetIndex++
            ) {
                if (
                    spreadAV[spreadAssetIndex].taskTypeAttribute
                        .name === "Fiber Distance (km)"
                ) {
                    spreadAV[
                        spreadAssetIndex
                    ].attributeValue = fiberDistance;
                }
            }
            vm.trTask.taskTypeAttributeValues = spreadAV;
            var assignedToUser = $("#field_assignedToUser").val();
            vm.trTask.assignedToUser = assignedToUser;

            callTask()
        }

        function callTask() {
            if (markers.length == 2) {
                markers.forEach(function (item) {
                    if (item.label.text == labelFrom || item.label.text == labelTo) {
                        if (item.label.text == labelFrom) {
                            if (vm.trTask.id) {
                                vm.trTask.fromRoutePoint.latitude = item.position.lat()
                                vm.trTask.fromRoutePoint.longitude = item.position.lng()
                            } else {
                                vm.trTask.fromRoutePoint = {
                                    "latitude": item.position.lat(), "longitude": item.position.lng()
                                };
                            }
                        }

                        if (item.label.text == labelTo) {
                            if (vm.trTask.id) {
                                vm.trTask.toRoutePoint.latitude = item.position.lat()
                                vm.trTask.toRoutePoint.longitude = item.position.lng()
                            } else {
                                vm.trTask.toRoutePoint = {
                                    "latitude": item.position.lat(), "longitude": item.position.lng()
                                };
                            }
                        }
                    }
                })
                if (vm.trTask.id) {
                    if (vm.trTask.verified) {
                        vm.trTask.approvedByUserId = $rootScope.loginUserId
                    }

                   /*  if(vm.assignToModified){
                        var projectId,routeId,sectionId
                        if(vm.trTask.sectionTask.length){
                            projectId = vm.trTask.sectionTask[0].projectId;
                            routeId = vm.trTask.sectionTask[0].routeId;
                            sectionId = vm.trTask.sectionTask[0].sectionId;

                        }else if(vm.trTask.routeTask.length){
                            projectId = vm.trTask.routeTask[0].projectId;
                            routeId = vm.trTask.routeTask[0].routeId;
                            sectionId = vm.trTask.routeTask[0].sectionId;
                        }
                        var data = {
                            "id": null,
                            "task":{
                                "id": vm.trTask.id
                            
                            },
                          "userId": $rootScope.loginUserId,
                          "tenantId": vm.trTask.tenantId,
                            "project":{
                                "id": projectId
                            
                            },
                            "route":{
                                "id": routeId
                            
                            },
                            "section":{
                                "id": sectionId
                            
                            }
                        }
                        TrUserTask.update(data)
                    } */
                    TrTask.update(vm.trTask, onSaveSuccess, onSaveError)
                } else {
                    TrTask.save(vm.trTask, onSaveSuccess, onSaveError)
                }
            } else {
                alert("Task From or To Points are Empty")
            }
        }

        function onSaveSuccess(result) {
            $window.history.back();
        }

        function onSaveError() {
        }


        Configs.get("WorkFlowStatus", function (response) {
            vm.workFlowStatus = [];
            var workFlowStatusList = response[0].configValue.split(",");
            workFlowStatusList.forEach(function (value) {
                if (value.length > 0) {
                    vm.workFlowStatus.push({ "configKey": value, "configValue": value });
                }
            });
        });

        Configs.get("Phases", function (response) {
            vm.workFlowPhase = response;
            //vm.workFlow.phase = "Planning Phase"
            /*  if(vm.workFlow.id == null){
                response.forEach(function(val){
                    if(val.configValue == '01'){
                        vm.workFlow.phase = val.configKey
                    }
                })
            }*/
        });


        /*  ActivatedUserSearch.query(
             {
                 // userId: vm.workFlow.assignedToUser,
                 userId: vm.workFlow.groups[0].id,
                 user: vm.user,
             },
             onSuccess,
             onError
         );
 
         function onSuccess(data, headers) {
             data.forEach(function (users) {
                 vm.assignedUsers.push(users.login);
             });
             vm.activatedusers = data;
 
             angular.forEach(vm.activatedusers, function (value, key) {
                 usersMap[value.login] = value.id;
             });
         }
         function onError(error) {
             AlertService.error(error.data.message);
         } */

        /*  RouteTypeSearch.query({userId:$scope.search,
             page: pagingParams.page - 1,
             size: vm.itemsPerPage,
             sort: sort()
         }, onSuccess, onError);
         function sort() {
             var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
             if (vm.predicate !== 'id') {
               //  result.push('id');
             }
             return result;
         }
         function onSuccess(data, headers) {
             vm.links = ParseLinks.parse(headers('link'));
             vm.totalItems = headers('X-Total-Count');
             vm.queryCount = vm.totalItems;
             vm.routeTypes = data;
             vm.page = pagingParams.page;
     
         }
         function onError(error) {
             AlertService.error(error.data.message);
         } */

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }


        //Task Map 
        var markers = []
        var coordinateList = []
        var chainageList = []
        var markerList = []
        var iconSize = 20
        var labelFrom, labelTo
        labelFrom = 'A'
        labelTo = 'B'
        var locations = {};

        locations.map = new google.maps.Map(
            document.getElementById("map_canvasStart"),
            {
                center: {
                    lat: parseFloat($rootScope.DEFAULT_LATITUDE),
                    lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
                },
                zoom: $rootScope.rolloutMapInitZoom,
                fullscreenControl: true,
                styles: mapStyles(),
            }
            //myOption(16, vm.getCenterStart)
        );

        vm.removeMarker = function () {
            markers.forEach(function (marker) {
                marker.setMap(null);
            });

            // Clear the markers array
            markers = [];

        }

        function addClick(item) {
            // Add a click event listener to the map
            item.addListener("click", function (event) {
                if (markers.length < 2) {
                    // Add a marker to the map
                    addMarker(event.latLng);

                    // Get the latitude and longitude of the clicked point
                    vm.pinLat = event.latLng.lat();
                    vm.pinLong = event.latLng.lng();
                } else {
                    console.log("Maximum 2 markers allowed.");
                }
            });
        }


        function createMarkers(arr, img, map) {
            arr.forEach(function (each) {
                var marker = new google.maps.Marker({
                    position: { lat: each.lat, lng: each.lng },
                    map: map,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: img,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                });

                google.maps.event.addListener(
                    marker,
                    "click",
                    (function () {
                        //var name = (each.name == (undefined || null)) ? " " : each.name
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        var userContent = document.createElement("div")

                        userContent.innerHTML = '<div class="infoWindowhead"></br></br>' +
                        /* "<b>Name : "+name+"</b></br>"+ */
                        "<b>Latitude : "+each.lat+"</b></br>"+
                        "<b>Longitude : "+each.lng+"</b></br>"+
                        "<b>Type : "+each.type+"</b></br>"+
                        "<b>Number : "+each.number+"</b></br>"+
                        "</br></div>"
                        infowindow.setContent(
                            userContent
                        );

                        infowindow.open(map);
                        infowindow.setPosition({
                            lat: each.lat,
                            lng: each.lng,
                        });
                        openedInfoWindow = infowindow;

                        if (markers.length < 2) {
                            btnAddSection = userContent.appendChild(
                                document.createElement("input")
                            );
                            btnAddSection.type = "button";

                            btnAddSection.value = "Add Task Point";
                            
                            btnAddSection.className = "button";

                            btnAddSection.addEventListener("click", function () {
                                if (markers.length < 2) {
                                    // Add a marker to the map
                                    addMarker({
                                        lat: each.lat,
                                        lng: each.lng,
                                    });
                    
                                    // Get the latitude and longitude of the clicked point
                                    vm.pinLat = each.lat;
                                    vm.pinLong = each.lng;
                                    openedInfoWindow.close();
                                } else {
                                    alert("From and To Task Exists");
                                }
                               })
                         }
                     }))

                //addClick(marker)
                arr.push(marker);
            })
        }

        function addMarker(location) {
            var markerText
            if (markers.length == 0) {
                markerText = labelFrom
            } else if (markers.length == 1) {
                markerText = labelTo
            }
            // Create a marker
            var marker = new google.maps.Marker({
                position: location,
                map: locations.map,
                label: {
                    text: markerText,
                },
                draggable: true
            });

            // Add the marker to the markers array
            markers.push(marker);
        }

        if(vm.trTask.sectionId != null){
            TaskSectionById.get(vm.trTask.sectionId, function (sectionVal) {
                showPathOnMap(sectionVal, coordinateList, chainageList, locations, addClick, createMarkers);
            });
        }

        if(vm.trTask.sectionId == null && vm.trTask.routeId != null){
            TaskRouteById.get(vm.trTask.routeId, function (routeVal) {
                showPathOnMap(routeVal, coordinateList, chainageList, locations, addClick, createMarkers);
            });
        }

        function showPathOnMap(sectionVal, coordinateList, chainageList, locations, addClick, createMarkers) {
            if (sectionVal.COORDINATE != null) {
                sectionVal.COORDINATE.forEach(function (coor) {
                    coordinateList.push({ lat: coor.latitude, lng: coor.longitude });
                });
            }
        
            if (sectionVal.CHAINAGE != null) {
                sectionVal.CHAINAGE.forEach(function (chainageVal) {
                    chainageList.push({ lat: chainageVal.latitude, lng: chainageVal.longitude,type : chainageVal.routePointType, number: chainageVal.routePointNumber });
                });
            }
        
            if (coordinateList.length) {
                locations.map.setCenter(coordinateList[0]);
                var polyline = new google.maps.Polyline({
                    path: coordinateList,
                    geodesic: true,
                    strokeColor: '#FD7A24',
                    strokeOpacity: 1.0,
                    strokeWeight: 4,
                });
        
                // Set the map to display the polyline
                polyline.setMap(locations.map);
                addClick(polyline);
            }
        
            if (chainageList.length) {
                locations.map.setCenter(chainageList[0]);
                createMarkers(chainageList, 'content/images/chainagePoints.png', locations.map);
            }
        }
        

        // if (section && section.id && section.fromRoutePoint && section.toRoutePoint) {
        //     addMarkerSection({ lat: section.fromRoutePoint.latitude, lng: section.fromRoutePoint.longitude }, "From Section")
        //     addMarkerSection({ lat: section.toRoutePoint.latitude, lng: section.toRoutePoint.longitude }, "To Section")
        //     locations.map.setCenter({ lat: section.fromRoutePoint.latitude, lng: section.fromRoutePoint.longitude });
        // }

        if (vm.trTask && vm.trTask.id && vm.trTask.fromRoutePoint && vm.trTask.toRoutePoint) {
            addMarker({ lat: vm.trTask.fromRoutePoint.latitude, lng: vm.trTask.fromRoutePoint.longitude })
            addMarker({ lat: vm.trTask.toRoutePoint.latitude, lng: vm.trTask.toRoutePoint.longitude })
            locations.map.setCenter({ lat: vm.trTask.fromRoutePoint.latitude, lng: vm.trTask.fromRoutePoint.longitude });
        }

        function findUsers(){
            UsersByVendorId.get(vm.selectedVendor,function(response) {
                vm.userList = [];
                response.forEach(function(user){
                    var hasRequiredRole = user.authorities.some(function(userAuth) {
                        return userAuth === 'ROLE_PLAN_SURVEY' || userAuth === 'ROLE_PLAN_ADMIN';
                    });
                    
                    if (hasRequiredRole) {
                        // Push the user details to the list
                        vm.userList.push({ name: user.login + " - " + user.authorities.join(', '), id: user.id });
                    }
                    
                    
                });
			});
        }

        function addMarkerSection(location, text) {
            // Create a marker
            var marker = new google.maps.Marker({
                position: location,
                map: locations.map,
                icon: {
                    scaledSize: new google.maps.Size(iconSize, iconSize),
                    url: "content/images/sections.png",
                    anchor: new google.maps.Point(
                        iconSize / 2,
                        -4
                    ),
                    labelOrigin: new google.maps.Point(25, 22),
                },
                label: {
                    text: text,
                }
            });

            // Add the marker to the markers array
            markersSection.push(marker);
        }

        vm.objectRouteImg = {}
        vm.objectRouteImg.routeId = $state.params.routeId
        vm.objectRouteImg.map  = locations.map

        $('.select2').select2();

        geoLocation(locations.map);

        function geoLocation(map) {
            var goTo = document.createElement("span");
            goTo.setAttribute("title", "Location Coordinates");
            goTo.classList.add("custom-map-control-button");
            var gotoIcon = document.createElement("i");
            gotoIcon.classList.add("fa");
            gotoIcon.classList.add("fa-location-arrow");
            gotoIcon.classList.add("geo");
            gotoIcon.classList.add("mt-5");
            goTo.appendChild(gotoIcon);
            map.controls[google.maps.ControlPosition.RIGHT_TOP].push(goTo);
            goTo.addEventListener("click", function () {
                vm.removeMarker()
                $("#modalLatLongs").show();
            });
        }

        vm.closeModal = function(id) {
            vm.getLocationCoordinateA = null
            vm.getLocationCoordinateB = null

            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide();
        }

        vm.onLocationCoordinates = function() {
            var latlngA = vm.getLocationCoordinateA.split(",");
            var latlngB = vm.getLocationCoordinateB.split(",");

            addMarker({lat: parseFloat(latlngA[0]),lng: parseFloat(latlngA[1])})
            addMarker({lat: parseFloat(latlngB[0]),lng: parseFloat(latlngB[1])})

            locations.map.setCenter({lat: parseFloat(latlngB[0]),lng: parseFloat(latlngB[1])})
            vm.closeModal('modalLatLongs') 
        }

            google.maps.event.addListener(locations.map, "click", function (event) {
                if (markers.length < 2) {
                    // Add a marker to the map
                    addMarker(event.latLng);
                } else {
                    console.log("Maximum 2 markers allowed.");
                }
            });
    }
})();


