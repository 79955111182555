(function () {
	"use strict";

	angular
		.module("trakeyeApp")
		.controller("TaskLogReportController", TaskLogReportController);
	angular.module("trakeyeApp").filter("trustFilter", [
		"$sce",
		function ($sce) {
			return function (text) {
				return $sce.trustAsHtml(text);
			};
		},
	]);

	TaskLogReportController.$inject = [
		"$scope",
		"$sce",
		"$state",
		"$localStorage",
		"$interval",
		"Report",
		"BaseUrl",
		"TrProjectListIdName",
		"TrRouteListByProjectId",
		"TrSectionListIdByRouteId",
		"TrTaskListIdBySectionId",
		"TaskLogReportData",
		"ValidationLogsBySectionId"
	];

	function TaskLogReportController(
		$scope,
		$sce,
		$state,
		$localStorage,
		$interval,
		Report,
		BaseUrl,
		TrProjectListIdName,
		TrRouteListByProjectId,
		TrSectionListIdByRouteId,
		TrTaskListIdBySectionId,
		TaskLogReportData,
		ValidationLogsBySectionId
	) {
		var vm = this;
		vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar;
		vm.userAgent = "username";
		vm.selectedReport = selectedReport;
		var date = "dd EEE";
		vm.today = today();
		vm.selecterange = selectRange;
		vm.downloadTaskLogReport = downloadTaskLogReport;
		vm.showTaskLogReport = showTaskLogReport;
		vm.isReportGenerated = false;
		$('.select2').select2();

		$(function () {
			$scope.getPdf = function () {
				$scope.loader = true;


				// Store the current zoom level of the browser
				var currentZoomLevel = document.body.style.zoom;

				// Set the zoom level to 100% (or any desired zoom level) to ensure accurate rendering
				document.body.style.zoom = '100%';

				var contentDiv = document.getElementById('generatedHtmlContainer');

				// Temporarily set the height of the div to auto to ensure all content is rendered
				var originalHeight = contentDiv.style.height;
				contentDiv.style.height = 'auto';

				// Scroll to the content
				contentDiv.scrollIntoView();

				// Get the dimensions of the div
				var width = contentDiv.scrollWidth + 200; // Use scrollWidth to capture entire content width
				var height = contentDiv.scrollHeight + 100; // Use scrollHeight to capture entire content height

				// Use html2canvas to capture the content of the div
				html2canvas(contentDiv, {
					width: width,
					height: height
				}).then(function (canvas) {
					// Restore the original height of the div
					contentDiv.style.height = originalHeight;

					// Create a PDF from the captured content
					var imgData = canvas.toDataURL('image/png');

					// Create PDF instance with landscape orientation
					var pdf = new jsPDF({
						orientation: 'landscape',
						unit: 'px',
						format: [height, width] // Set format to match landscape orientation
					});

					pdf.addImage(imgData, 'PNG', 50, 50, width, height);

					// Save the PDF
					pdf.save('report.pdf');

					// Restore the browser's zoom level
					document.body.style.zoom = currentZoomLevel;
				});





				$interval(function () {
				}, 1000);
			}
		});

		function downloadTaskLogReport() {
			BaseUrl.getBaseUrl(function (baseurl) {
				var url = "";
				url = baseurl.stringValue + "api/task-log-export-excel/" + vm.fromTime + "/" + vm.toTime + "/" + vm.reportType + "/" + vm.selectedProjectId + "/" + vm.selectedRouteId;
				if (vm.selectedSectionId > 0) {
					url = baseurl.stringValue + "api/task-log-export-excel/" + vm.fromTime + "/" + vm.toTime + "/" + vm.reportType + "/" + vm.selectedProjectId + "/" + vm.selectedRouteId + "/" + vm.selectedSectionId;
				}
				var request = new XMLHttpRequest();
				request.open('GET', url, true);
				request.setRequestHeader('Authorization', 'Bearer ' + $localStorage.authenticationToken);
				request.responseType = 'blob';

				request.onload = function (e) {
					if (this.status === 200) {
						var blob = this.response;
						if (blob.size > 0) {
							if (window.navigator.msSaveOrOpenBlob) {
								window.navigator.msSaveBlob(blob, 'test.xlsx');
							}
							else {
								var downloadLink = window.document.createElement('a');
								var contentTypeHeader = request.getResponseHeader("Content-Type");
								downloadLink.href = window.URL.createObjectURL(new Blob([blob], {
									type: contentTypeHeader
								}));
								downloadLink.download = 'Report.xlsx';
								if (vm.reportType == 'daily') {
									downloadLink.download = 'DailyTaskLogReport.xlsx';
								} else if (vm.reportType == 'measurement') {
									downloadLink.download = 'MeasurementReport.xlsx';
								}

								document.body.appendChild(downloadLink);
								downloadLink.click();
								document.body.removeChild(downloadLink);
							}
						}
					}
				};
				request.send();
			});
		}

		function today() {
			var today = new Date();
			vm.toDate = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate()
			);
			vm.fromDate = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate()
			);
			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}
		vm.datePickerOpenStatus.createdDate = false;
		vm.datePickerOpenStatus.modifiedDate = false;
		vm.datePickerOpenStatus.serviceDate = false;

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}

		vm.calculateToDate = calculateToDate;
		vm.calculateFromDate = calculateFromDate;
		function calculateFromDate() {
			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
		}
		function calculateToDate() {
			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}

		function selectRange(range) {
			if (range == "day") {
				var today = new Date();
				vm.toDate = new Date(
					today.getFullYear(),
					today.getMonth(),
					today.getDate()
				);
				vm.fromDate = new Date(
					today.getFullYear(),
					today.getMonth(),
					today.getDate()
				);
				vm.fromDate.setHours(0, 0, 0, 0);
				vm.fromTime = vm.fromDate.getTime();
				vm.toDate.setHours(23, 59, 59, 999);
				vm.toTime = vm.toDate.getTime();
			} else if (range == "week") {
				var date = new Date();
				date.setHours(23, 59, 59, 999);
				vm.toTime = date.getTime();
				// date.setHours(0, 0, 0, 0);
				date.setDate(date.getDate() - 7);
				vm.fromTime = date.getTime();
			} else if (range == "month") {
				var date = new Date();
				date.setHours(23, 59, 59, 999);
				vm.toTime = date.getTime();
				// date.setHours(0, 0, 0, 0);
				date.setMonth(date.getMonth() - 1);
				vm.fromTime = date.getTime();
			}
		}

		function selectedReport() {
			if (vm.reportType == 'daily') {
				vm.selectedSectionId = "";
			}
			TrProjectListIdName.get(function (response) {
				vm.projects = response;
				if (vm.projects.length) {
					vm.projects.sort(function (a, b) {
						var nameA = a.name.toUpperCase(); // Convert names to uppercase for case-insensitive sorting
						var nameB = b.name.toUpperCase();

						if (nameA < nameB) {
							return -1;
						}
						if (nameA > nameB) {
							return 1;
						}
						return 0; // Names are equal
					});
				}
			});
		}

		$scope.onProjectChange = function () {
			TrRouteListByProjectId.get(
				vm.selectedProjectId,
				function (response) {
					vm.routes = response;
				}
			);
		};

		$scope.onRouteChange = function () {
			TrSectionListIdByRouteId.get(
				vm.selectedRouteId,
				function (response) {
					vm.sections = response;
				}
			);
		};

		$scope.onSectionChange = function () {
			TrTaskListIdBySectionId.get(
				vm.selectedSectionId,
				function (response) {
					vm.tasks = response;
				}
			);
		};

		$scope.onTaskChange = function () {
			console.log(vm.selectedTaskId);
		};

		function createMetaTag(attrName, attrValue, content) {
			var meta = document.createElement('meta');
			meta.setAttribute(attrName, attrValue);
			meta.setAttribute('content', content);
			return meta;
		}

		function createTable(classNames) {
			var table = document.createElement('table');
			table.setAttribute('border', '1');
			table.setAttribute('cellpadding', '5');
			table.setAttribute('cellspacing', '0');
			table.setAttribute('id', 'sheet0');
			table.setAttribute('class', classNames);
			return table;
		}

		function createTableCell(tagName, textContent) {
			var cell = document.createElement(tagName);
			cell.textContent = textContent;
			return cell;
		}


		function showTaskLogReport() {
			vm.isReportGenerated = false;
			TaskLogReportData.query(
				{
					fromDate: vm.fromTime,
					toDate: vm.toTime,
					reportType: vm.reportType,
					projectId: vm.selectedProjectId,
					routeId: vm.selectedRouteId,
					sectionId: vm.selectedSectionId,
					taskId: vm.selectedTaskId
				},
				onSuccess,
				onError
			);

			function onSuccess(data) {
				if (vm.reportType == 'daily') {
					var htmlDoc = document.createElement('html');
					var head = document.createElement('head');
					var meta1 = createMetaTag('http-equiv', 'Content-Type', 'text/html; charset=utf-8');
					var meta2 = createMetaTag('name', 'generator', 'PhpSpreadsheet, https://github.com/PHPOffice/PhpSpreadsheet');
					var meta3 = createMetaTag('name', 'author', 'Apache POI');
					var meta4 = createMetaTag('name', 'company', 'Microsoft Corporation');
					head.appendChild(meta1);
					head.appendChild(meta2);
					head.appendChild(meta3);
					head.appendChild(meta4);
					var body = document.createElement('body');
					var table = createTable('sheet0 gridlines');
					var thead = document.createElement('thead');
					var tbody = document.createElement('tbody');

					function createTable(classNames) {
						var table = document.createElement('table');
						table.setAttribute('border', '1');
						table.setAttribute('cellpadding', '5');
						table.setAttribute('cellspacing', '0');
						table.setAttribute('id', 'sheet0');
						table.setAttribute('class', classNames);
						return table;
					}

					function createMetaTag(attrName, attrValue, content) {
						var meta = document.createElement('meta');
						meta.setAttribute(attrName, attrValue);
						meta.setAttribute('content', content);
						return meta;
					}

					function createTableCell(tag, content) {
						var cell = document.createElement(tag);
						cell.textContent = content;
						cell.style.fontSize = "13px";
						return cell;
					}

					thead.className = 'thReport';
					table.appendChild(thead);
					tbody.className = 'tdReport';
					table.appendChild(tbody);
					var styleElement = document.createElement('style');
					styleElement.textContent = "\
                .subheader {\
                    margin: auto;\
                }\
                .thReport > tr > th{\
                    text-align: center;\
                    padding: 10px;\
                }\
                .tdReport > tr > td {\
                    text-align: center; \
                    padding: 10px;\
                }\
                ";
					document.head.appendChild(styleElement);

					var colors = ['#d6d5c9', '#ff9b85', '#ffcccc', '#ccccff', '#fae588', '#b0f2b4', '#abd3ff', '#d0b17a', '#eacdc2',];
					function getRandomColor() {
						if (colors.length === 0) {
							colors = ['#d6d5c9', '#ff9b85', '#ffcccc', '#ccccff', '#fae588', '#b0f2b4', '#abd3ff', '#d0b17a', '#eacdc2'];
						}
						var index = Math.floor(Math.random() * colors.length);
						var color = colors[index];
						colors.splice(index, 1);
						return color;
					}


					var groupedData = {};
					data.forEach(function (item) {
						if (!groupedData[item.date]) {
							groupedData[item.date] = [];
						}
						groupedData[item.date].push(item);
					});
					var names = getUniqueNames(data);
					var nameHeaderRow = document.createElement('tr');
					var serialNumberHeader = createTableCell('th', 'S.No');
					serialNumberHeader.style.fontWeight = 'bold';
					serialNumberHeader.style.backgroundColor = '#7dcfb6';
					serialNumberHeader.rowSpan = 2;
					nameHeaderRow.appendChild(serialNumberHeader);
					var dateHeader = createTableCell('th', 'Date');
					dateHeader.style.backgroundColor = '#7dcfb6';
					dateHeader.style.fontWeight = 'bold';
					dateHeader.rowSpan = 2;
					nameHeaderRow.appendChild(dateHeader);
					var typeHeaderRow = document.createElement('tr');

					names.forEach(function (name, index) {
						var nameData = data.filter(function (item) { return item.name === name; });
						var availableTypes = getUniqueTypes(nameData);
						var nameHeaderCell = createTableCell('th', name);
						nameHeaderCell.style.backgroundColor = getRandomColor();
						nameHeaderCell.style.fontWeight = 'bold';
						nameHeaderCell.colSpan = availableTypes.length;
						nameHeaderRow.appendChild(nameHeaderCell);
						availableTypes.forEach(function (type) {
							var typeHeaderCell = createTableCell('th', type);
							typeHeaderCell.style.backgroundColor = nameHeaderCell.style.backgroundColor;
							typeHeaderCell.style.fontWeight = 'bold';
							typeHeaderRow.appendChild(typeHeaderCell);
						});
					});
					var totalProgressHeader = createTableCell('th', 'Total Progress');
					totalProgressHeader.style.backgroundColor = '#cfe0c3';
					totalProgressHeader.style.fontWeight = 'bold';
					totalProgressHeader.colSpan = 3;
					nameHeaderRow.appendChild(totalProgressHeader);
					var subHeaders = [
						{
							type: 'Total',
						},
						{
							type: "Ducting",
						}, {
							type: "Validation",

						}
					];
					subHeaders.forEach(function (subHeader) {
						var subHeaderCell = createTableCell('th', subHeader.type);
						subHeaderCell.style.backgroundColor = '#cfe0c3';
						subHeaderCell.style.fontWeight = 'bold';
						typeHeaderRow.appendChild(subHeaderCell);
					});
					thead.appendChild(nameHeaderRow);
					thead.appendChild(typeHeaderRow);
					var totals = {};

					Object.keys(groupedData)
						.sort(function (a, b) {
							var dateA = a.split('-').reverse().join('-');
							var dateB = b.split('-').reverse().join('-');
							return new Date(dateA) - new Date(dateB);
						})
						.forEach(function (date, index) {
							var totalLength = 0;
							var totalValidation = 0;
							names.forEach(function (name) {
								var nameData = data.filter(function (item) { return item.name === name && item.date === date; });
								var ductingLength = 0;
								var hddLength = 0;
								var horizontalDirectionalDrilling = 0
								var validationLength = 0;
								nameData.forEach(function (item) {
									if (item.type === 'Ducting') {
										ductingLength += item.length;
									}
									else if (item.type === 'HDD') {
										hddLength += item.length;

									}
									else if (item.type === 'HDD ( Horizontal Directional Drilling )') {
										horizontalDirectionalDrilling += item.length;
										console.log(horizontalDirectionalDrilling)
									}
									else if (item.type === 'Validation' || item.type === 'Validation (T&D)') {
										validationLength += item.length;
									}
								});
								console.log(totalLength)
								totalLength += (ductingLength + hddLength + horizontalDirectionalDrilling);
								totalValidation += validationLength;

							});
							var tr = document.createElement('tr');
							var serialNumberCell = createTableCell('td', index + 1);
							serialNumberCell.style.fontSize = "13px";
							tr.appendChild(serialNumberCell);
							var dateCell = createTableCell('td', date);
							dateCell.style.width = '77px'
							dateCell.style.whiteSpace = 'nowrap';
							dateCell.style.fontSize = "13px";
							tr.appendChild(dateCell);
							names.forEach(function (name) {
								var nameData = data.filter(function (item) { return item.name === name; });
								var availableTypes = getUniqueTypes(nameData);

								availableTypes.forEach(function (type) {
									var item = groupedData[date].find(function (item) {
										return item.name === name && item.type === type;
									});
									var lengthValue = item ? item.length : 0;
									var td = createTableCell('td', lengthValue);

									tr.appendChild(td);
									if (!totals[type]) {
										totals[type] = 0;
									}
									totals[type] += lengthValue;
								});
							});
							var total = 0;
							for (var i = 2; i < tr.cells.length; i++) {
								total += parseInt(tr.cells[i].textContent);
							}
							var columnValueCell = createTableCell('td', total);
							columnValueCell.colSpan = 1;
							tr.appendChild(columnValueCell);
							var remainingDuctingCell = createTableCell('td', totalLength);
							tr.appendChild(remainingDuctingCell);
							var remainingValidationCell = createTableCell('td', totalValidation);
							tr.appendChild(remainingValidationCell);
							if (index === 0) {
								totals['Total Progress Ducting'] = 0;
								totals['Total Progress Validation'] = 0;
							}
							totals['Total Progress Ducting'] += totalLength;
							totals['Total Progress Validation'] += totalValidation;
							tbody.appendChild(tr);
						});

					var staticColumns = ['Total', 'Balance Validation', 'Balance Ducting'];
					var allAvailableTypes = [];
					names.forEach(function (name) {
						var nameData = data.filter(function (item) { return item.name === name; });
						allAvailableTypes.push.apply(allAvailableTypes, getUniqueTypes(nameData));
					});
					var availableTypes = allAvailableTypes;
					var maxUniqueTypesCount = availableTypes.length;
					var totalLength = maxUniqueTypesCount + subHeaders.length;

					var columnTotals = {};
					for (var i = 2; i < totalLength; i++) {
						var total = 0;
						for (var j = 0; j < tbody.rows.length; j++) {
							var cellValue = parseInt(tbody.rows[j].cells[i].textContent) || 0;
							total += cellValue;
						}
						columnTotals[i] = total;
					}
					staticColumns.forEach(function (column) {
						var staticRow = document.createElement('tr');
						var columnNameCell = createTableCell('td', column);
						columnNameCell.colSpan = 2;
						columnNameCell.style.backgroundColor = '#cfe0c3';
						columnNameCell.style.fontSize = "13px";
						columnNameCell.style.fontWeight = 'bold';
						staticRow.appendChild(columnNameCell);
						if (column == 'Total') {
							for (var i = 2; i < totalLength; i++) {
								var columnValueCell = createTableCell('td', columnTotals[i].toString());
								columnValueCell.colSpan = 1;
								staticRow.appendChild(columnValueCell);
							}
							var columnValueCell = createTableCell('td', totals['Total Progress Ducting']);
							columnValueCell.colSpan = 1;
							staticRow.appendChild(columnValueCell);
							var columnValueCell = createTableCell('td', totals['Total Progress Validation']);
							columnValueCell.colSpan = 1;
							staticRow.appendChild(columnValueCell);
						} else {
							for (var i = 0; i < totalLength; i++) {
								var columnValueCell = createTableCell('td', '0');
								columnValueCell.colSpan = 1;
								staticRow.appendChild(columnValueCell);
							}
						}
						tbody.appendChild(staticRow);
					});
					table.appendChild(thead);
					table.appendChild(tbody);
					body.appendChild(table);
					htmlDoc.appendChild(body);
					htmlDoc.appendChild(head);
					var htmlString = htmlDoc.outerHTML;
					var containerDiv = document.getElementById("generatedHtmlContainer");
					containerDiv.innerHTML = htmlString;

					function getUniqueNames(data) {
						var uniqueNames = [];
						data.forEach(function (item) {
							if (uniqueNames.indexOf(item.name) === -1) {
								uniqueNames.push(item.name);
							}
						});
						return uniqueNames;
					}
					function getUniqueTypes(data) {
						var uniqueTypes = [];
						data.forEach(function (item) {
							if (uniqueTypes.indexOf(item.type) === -1) {
								uniqueTypes.push(item.type);
							}
						});
						return uniqueTypes;
					}

					// Set background color for specified cells
					var jcbCells = document.querySelectorAll('.row1 .column0'); // Select cells in row 1, column 0 (JCB)
					var hddCells = document.querySelectorAll('.row1 .column1'); // Select cells in row 1, column 1 (HDD)

					// Loop through JCB cells and set background color to light green
					jcbCells.forEach(function (cell) {
						cell.style.backgroundColor = 'lightgreen';
					});

					// Loop through HDD cells and set background color to light green
					hddCells.forEach(function (cell) {
						cell.style.backgroundColor = 'lightgreen';
					});

					// Get the Total row
					// Select all relevant rows
					var relevantRows = ['.row2', '.row3', '.row5', '.row6'];

					// Loop through each relevant row
					relevantRows.forEach(function (rowClass) {
						var cells = document.querySelectorAll(rowClass + ' td');

						// Loop through the cells in the row
						cells.forEach(function (cell) {
							// Check if the content of the cell is '0' or empty
							if (cell.textContent.trim() === '0' || cell.textContent.trim() === '') {
								// Set background color to white
								cell.style.backgroundColor = 'white';
							}
						});
					});

					vm.isReportGenerated = true;
				} else {

					ValidationLogsBySectionId.query(
						{
							sectionId: vm.selectedSectionId
						},
						onSuccess,
						onError
					);
					function onSuccess(data) {
						console.log(data);
					}

					function onError(error) {
						AlertService.error(error.data.message);
					}

					var htmlDoc = document.createElement('html');
					var head = document.createElement('head');
					var meta1 = document.createElement('meta');
					meta1.setAttribute('http-equiv', 'Content-Type');
					meta1.setAttribute('content', 'text/html; charset=utf-8');
					var meta2 = document.createElement('meta');
					meta2.setAttribute('name', 'generator');
					meta2.setAttribute('content', 'PhpSpreadsheet, https://github.com/PHPOffice/PhpSpreadsheet');
					var meta3 = document.createElement('meta');
					meta3.setAttribute('name', 'author');
					meta3.setAttribute('content', 'Apache POI');
					var meta4 = document.createElement('meta');
					meta4.setAttribute('name', 'company');
					meta4.setAttribute('content', 'Microsoft Corporation');
					head.appendChild(meta1);
					head.appendChild(meta2);
					head.appendChild(meta3);
					head.appendChild(meta4);

					var body = document.createElement('body');
					var table = document.createElement('table');
					table.setAttribute('border', '0');
					table.setAttribute('cellpadding', '0');
					table.setAttribute('cellspacing', '0');
					table.setAttribute('id', 'sheet0');
					table.setAttribute('class', 'sheet0 gridlines');

					var projectName = "";
					var sectionName = "";
					vm.projects.forEach(function (value) {
						if (value.id == vm.selectedProjectId) {
							projectName = value.name;
						}
					});
					vm.sections.forEach(function (value) {
						if (value.id == vm.selectedSectionId) {
							sectionName = value.name;
						}
					});

					var specialRows = [
						{ class: 'row0', content: ['NHLML- National Highways Management Limited'], },
						{ class: 'row1', content: ['Trenching & Ducting Validation Sheet For Open Trench'] },
						{ class: 'row2', content: ['projectName', projectName] },
						{ class: 'row3', content: ['Contactor', ''] },
						{ class: 'row4', content: ['Date', ''] },
						{ class: 'row5', content: ['Measurement Book Number(MBN)', ''] },
						{ class: 'row6', content: ['Sheet No', '1'] },
						{ class: 'row7', content: ['Section Name', sectionName] },
						{ class: 'row8', content: ['State Name', ''] },
						{ class: 'row9', content: ['PIU', ''] },
						{ class: 'row10', content: ['Package', ''] },
						{ class: 'row11', content: [''] },
					];

					specialRows.forEach(function (rowData, rowIndex) {
						var tr = document.createElement('tr');
						tr.setAttribute('class', rowData.class);

						rowData.content.forEach(function (cellData, index) {
							var td = document.createElement('td');
							td.setAttribute('class', 'column' + index);
							td.textContent = cellData;
							td.style.border = '1px solid black';
							td.style.fontSize = "14px";
							td.style.fontWeight = 'bold';
							if (rowIndex === 11 && index === 0) {
								td.colSpan = 27;
								var br = document.createElement('br');
								td.appendChild(br);
							}
							if (rowIndex < 2) {
								td.colSpan = 27;
								td.style.textAlign = 'center';
							}
							if (rowIndex >= 2 && rowIndex <= 10 && index === 1) {
								td.colSpan = 26;
								td.style.textAlign = 'left';
							}
							if (cellData === 'projectName' || cellData === 'Contactor' || cellData === 'Date' || cellData === 'Measurement Book Number(MBN)' || cellData === 'Sheet No' || cellData === 'Section Name' || cellData === 'State Name' || cellData === 'PIU' || cellData === 'Package') {
								td.style.backgroundColor = 'yellow';
							}
							tr.appendChild(td);
						});

						table.appendChild(tr);
					});

					var rowTags = [
						{ class: 'row0', content: ['SL', 'Chainage', 'Actual Length', 'Row Ref', 'Approved Rows', 'width', 'Actual Ref Available', 'Trench Details', 'Bidding Details', 'Bend Details', 'Duct Joint Details', 'No of Duct Laid', 'Duct Protection', 'End Caps Req', 'Coupling Point location', 'Remarks'], bgColor: 'yellow' }, // Added 4 empty columns after 'CH 10 TO 20'
						{ class: 'row1', content: ['From', 'To', 'Methodology Open trench(OT)', 'Crossing by Hume Pipe on Interchange(y/n)', 'X (Offset)in (Mtr)', 'D(Depth) in (Mtr)', 'Top width in MM', 'Bottom Width In MM', 'Strata', 'Height mm', 'Ramming', 'Check cleanliness of duct at either end', 'visual check for tightness of  coupler', '63/50 mm(violet,Grey,Red)', '40/33 mm(Blue,yellow,Brown)', 'PCC/RCC/GI/DWC/Tiles', 'Length(Mtr)',], bgColor: 'yellow' },
						{ class: 'row2', content: ['Particulars', 'Contractor representative', 'Authority Engineer representative', 'NHLML Representative',] },
						{ class: 'row3', content: ['Signature', '', '', ''] },
						{ class: 'row4', content: ['Name', '', '', ''] },
						{ class: 'row5', content: ['Designation', '', '', ''] },
						{ class: 'row6', content: ['Date', '', '', ''] }
					];
					var loopCounter = 0;

					data.forEach(function (item, index) {
						rowTags.splice(2 + index, 0, {
							class: 'row2',
							content: [
								index + 1,
								item.chainageFrom || '',
								item.chainageTo || '',
								item.actualLengthMeters || '',
								item.rowRefLhsRhs || '',
								item.approvedRowAsPerRefMeters || '',
								item.widthAvailableInUtilityCorridorFromRowBoundaryWall || '',
								item.actualRowAvailableMeters || '',
								item.methodologyOpenTrench || '',
								item.crossingByHumePipeOnInterchange || '',
								item.xOffsetMeters || '',
								item.dOffsetMeters || '',
								item.topWidthMm || '',
								item.bottomWidthMm || '',
								item.strata || '',
								item.heightMm || '',
								item.ramming || '',
								item.bendDetails || '',
								item.checkCleanlinessOfDuctAtEitherEnd || '',
								item.visualCheckForTightnessOfCoupler || '',
								item.mm63_50VioletGreyRed || '',
								item.mm40_33BlueYellowBrown || '',
								item.pccRccGiDwcTiles || '',
								item.lengthMeters || '',
								item.endCapsProvided || '',
								item.couplingPointLocation || '',
								item.remarks || ''
							]
						});

						loopCounter++;
					});

					rowTags.forEach(function (rowData, rowIndex) {
						var tr = document.createElement('tr');
						tr.setAttribute('class', rowData.class);
						tr.style.backgroundColor = rowData.bgColor || 'transparent';
						tr.style.textAlign = 'center';
						rowData.content.forEach(function (cellData, index) {
							var td = document.createElement('td');
							td.setAttribute('class', 'column' + index);
							if (Array.isArray(cellData)) {
								cellData.forEach(function (subCellData) {
									var subTd = document.createElement('td');
									subTd.textContent = subCellData;
									subTd.style.border = '1px solid black';
									tr.appendChild(subTd);
								});
							} else {
								td.textContent = cellData;
								td.style.border = '1px solid black';
								td.style.fontSize = "12px";
								if (rowIndex === 0 && index <= 0) {
									td.rowSpan = 2;
								}
								if (rowIndex === 0 && index >= 2 && index <= 6) {
									td.rowSpan = 2;
								}
								if (rowIndex === 0 && index >= 7 && index <= 7) {
									td.colSpan = 7;
								}
								if (rowIndex === 0 && index >= 8 && index <= 8) {
									td.colSpan = 2;
								}
								if (rowIndex === 0 && index >= 10 && index <= 10) {
									td.colSpan = 2;
								}
								if (rowIndex === 0 && index >= 11 && index <= 11) {
									td.colSpan = 2;
								}
								if (rowIndex === 0 && index >= 9 && index <= 9) {
									td.rowSpan = 2;
								}
								if (rowIndex === 0 && index >= 12 && index <= 12) {
									td.colSpan = 2;
								}
								if (rowIndex === 0 && index >= 13 && index <= 15) {
									td.rowSpan = 2;
								}
								if (rowIndex === 0 && index >= 1 && index <= 1) {
									td.colSpan = 2;
								}
								if (rowIndex === 0 && index >= 2 && index <= 2) {
									td.colSpan = 1;
								}
								if (rowIndex === loopCounter + 2 && index >= 1 && index <= 3) {
									td.colSpan = 9;
								}
								if (rowIndex === loopCounter + 3 && index >= 1 && index <= 3) {
									td.colSpan = 9;
								}
								if (rowIndex === loopCounter + 4 && index >= 1 && index <= 3) {
									td.colSpan = 9;
								}
								if (rowIndex === loopCounter + 5 && index >= 1 && index <= 3) {
									td.colSpan = 9;
								}
								if (rowIndex === loopCounter + 6 && index >= 1 && index <= 3) {
									td.colSpan = 9;
								}

								if ((rowIndex === 0 && index === 2) || (rowIndex === 0 && index === 2)) {
									td.style.backgroundColor = 'yellow';
								}

								if (rowIndex === 1 && (index === 0 || index === 1)) {
									td.style.backgroundColor = 'yellow';
								}
								tr.appendChild(td);
							}
							if (index < 1) {
								if (rowIndex === 14 || rowIndex === 16) {
									td.style.backgroundColor = 'yellow';
								} else {
									td.style.backgroundColor = 'yellow';
								}
							}
						});
						table.appendChild(tr);
					});

					body.appendChild(table);
					htmlDoc.appendChild(head);
					htmlDoc.appendChild(body);
					var htmlString = htmlDoc.outerHTML;

					var generatedHtml = htmlString;

					var containerDiv = document.getElementById(
						"generatedHtmlContainer"
					);
					containerDiv.innerHTML = generatedHtml;

					vm.isReportGenerated = true;
				}
			}
		}

		function onError(error) {
			AlertService.error(error.data.message);
		}

	}


})();