(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("ResourceTxnDialogController", ResourceTxnDialogController);

    ResourceTxnDialogController.$inject = ['$state','Inventory','TrResource','$scope','ParseLinks','AlertService',
    'pagingParams','$timeout','$window','entity','entityId'];

    function ResourceTxnDialogController($state,Inventory,TrResource,
        $scope,
        ParseLinks,
        AlertService,
        pagingParams,
        $timeout,
        $window,
        entity,
        entityId
        ) {
        var vm = this;
        vm.resourceTxn = {}
        vm.transactionType = ["REQUESTED","UTILIZED"]
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        var objName = $state.params.entityName
        vm.isSaving = true
        vm.selectedResource = []

        /* var today = new Date();
        var todayFormatted = today.toISOString().split("T")[0];
        document.getElementById("dateInput").max = todayFormatted; */


       /*  if($state.params.entityId){
            vm.resourceTxn.entityId  = parseInt($state.params.entityId, 10)
        } */

        TrResource.query(
            {
                page: 0,
                size: 100,
                sort: sort(),
            },
            onSuccess,
            onError
        );

        function sort() {
            var result = [
                vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
            ];
            if (vm.predicate !== "id") {
                //  result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers("link"));
            vm.totalItems = headers("X-Total-Count");
            vm.queryCount = vm.totalItems;
            vm.resources = data
            
            vm.page = pagingParams.page;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        if(entity){
            Inventory.getResourceTxn(entityId,entity,function(response){
                vm.resourceTxn = response
                if(vm.resourceTxn[0].id){
                    vm.resourceTxn[0].transactionDate = new Date(vm.resourceTxn[0].transactionDate);
                }
            })
            vm.isShow = true
            vm.isSaving = false
        }else{
            vm.resourceTxn = []
            /* if($state.params.entityId){
                vm.resourceTxn.entityId  = parseInt($state.params.entityId, 10)
            } */
           
        }

        vm.save = function(){
            //vm.resourceTxn.resourceId = parseInt(vm.resourceTxn.resource)

            /* if(vm.resourceTxn.transactionDate){
                vm.resourceTxn.transactionDate = epoch(vm.resourceTxn.transactionDate)
            } */
            
            if(vm.resourceTxn[0].id){
                vm.dateChanged(0)
                Inventory.putResourceTxn(vm.resourceTxn[0],function(){
                        $window.history.back();
                })
             }else{
                vm.resourceTxn.forEach(function (each){
                    each.transactionDate =  epoch(each.transactionDate)
                })
                Inventory.postBulkResourceTxn(vm.resourceTxn,function(){
                        $window.history.back();
                })

            }
            
        }

        var today = new Date();
        today.setHours(23, 59, 59);
        vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar; 
        vm.options = {
                maxDate: today
            };

            function openCalendar(date) {
                vm.datePickerOpenStatus[date] = true;
            }

           
            
            function epoch (date) {
                return Date.parse(date)
              }


              $scope.addDetails = function () { 
                //vm.resourceTxn.push({entityId:$state.params.entityId})
              }

              vm.dateChanged = function(index){
                if(vm.resourceTxn[index].transactionDate){
                    vm.resourceTxn[index].transactionDate = epoch(vm.resourceTxn[index].transactionDate)
                }  
              }

              $scope.removeDetails = function (id) {
                vm.resourceTxn.splice(id, 1);

                if(!vm.resourceTxn.length){
                    vm.isSaving = true
                }
            };

            $('.select2').select2();
    
             $scope.type = {
                resources: {
                    search: "Search",
                    nothingSelected: "Resources",
                    selectAll: "All",
                    selectNone: "None",
                    Done: "Done",
                }
            };
    
            vm.onSelectedResource = function (){
                if(vm.selectedResource.length && vm.selectTransactionType && vm.selectDate){
                    //vm.resourceTxn = []
                    vm.selectedResource.forEach(function (each,index){
                        var exists = vm.resourceTxn.some(function(element) { 
                            element.transactionDate = vm.selectDate
                            element.transactionType = vm.selectTransactionType
                            return (element.resourceId === each.id)
                        } ); 
    
                        var temp  = vm.resources.find(function (eachElement){
                            return eachElement.id == each.id
                        })
     
                         if (!exists) {
                            vm.resourceTxn.push({
                                "resourceId": each.id,
                                "unitOfMeasurement": temp.unitOfMeasurement,
                                "noOfUnits": null,
                                "transactionDate": vm.selectDate,
                                "transactionType": vm.selectTransactionType,
                                "invoiceNumber": null,
                                "remarks": null
                            })
    
                         vm.resourceTxn[index][objName] = $state.params.entityId
                        
                        }  
    
                     /*     vm.resourceTxn.push({
                            "resourceId": each.id,
                            "noOfResources": null,
                            "numberOfHours": null,
                            "transactionDate": vm.selectDate,
                            "transactionType": vm.selectTransactionType,
                            'sectionId' : $state.params.sectionId
                        })
    
                     vm.resourceTxn[index][objName] = parseInt($state.params.entityId) */
                        
                    })
    
                    vm.isSaving = false
                    //$scope.$apply()
                }
            }
       
    }
})();
 