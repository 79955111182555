(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .factory('Principal', Principal);

    Principal.$inject = ['$q', 'Account','$rootScope','Configs'];

    function Principal ($q, Account,$rootScope,Configs) {
        var _identity,
            _authenticated = false;

        var service = {
            authenticate: authenticate,
            hasAnyAuthority: hasAnyAuthority,
            hasAuthority: hasAuthority,
            identity: identity,
            isAuthenticated: isAuthenticated,
            isIdentityResolved: isIdentityResolved
        };

        return service;

        function authenticate (identity) {
            _identity = identity;
            _authenticated = identity !== null;
        }

        function hasAnyAuthority (authorities) {
            if (!_authenticated || !_identity || !_identity.authorities) {
                return false;
            }

            for (var i = 0; i < authorities.length; i++) {
                if (_identity.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }

            return false;
        }

        function hasAuthority (authority) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function(_id) {
                return _id.authorities && _id.authorities.indexOf(authority) !== -1;
            }, function(){
                return false;
            });
        }

        function identity (force) {
            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
            }

            // check and see if we have retrieved the identity data from the server.
            // if we have, reuse it by immediately resolving
            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);

                return deferred.promise;
            }

            // retrieve the identity data from the server, update the identity object, and then resolve.
            Account.get().$promise
                .then(getAccountThen)
                .catch(getAccountCatch);

            return deferred.promise;

            function getAccountThen (account) {
                $rootScope.loginUserId = account.data.id 
                $rootScope.rolloutMapInitZoom = 15
                $rootScope.loginUserName = account.data.login 
               /*  $rootScope.isVendorAdmin = account.data.authorities.find(function (each){
                    if(each == "ROLE_VENDOR_ADMIN" || each == "ROLE_ROLLOUT_MANAGER" || each == "ROLE_ROLLOUT_ADMIN" ) {return true} 
                })  */

                
                    Configs.rolloutFeatures(function (response) {
                        $rootScope.Roles = {}
                        if (response && response.length) {
                            response.forEach(function (value) {
                                $rootScope.Roles[value.configKey] = true 
    
                                if(value.configKey == "noOfWorkingHours"){
                                    $rootScope.maxNoOfWorkingHours = parseInt(value.configValue)
                                }
                            });
                            if($rootScope.Roles){
                                for (var key in $rootScope.Roles) {
                                    if ($rootScope.Roles.hasOwnProperty(key)) {
                                        account.data.authorities.push(key);
                                    }
                                  }
                            }
                        }
                        _identity = account.data;
                        _authenticated = true;
                        deferred.resolve(_identity);
                    },function (err){
                        $rootScope.Roles = {}
                    
                        _identity = account.data;
                        _authenticated = true;
                        deferred.resolve(_identity);
                    });
                   
                

                $rootScope.isVendorAdmin = account.data.authorities.find(function (each){
                    if(each == "ROLE_VENDOR_ADMIN") {return true} 
                })
                $rootScope.isRolloutAdmin = account.data.authorities.find(function (each){
                    if(each == "ROLE_ROLLOUT_ADMIN") {return true} 
                })
                $rootScope.isManager = account.data.authorities.find(function (each){
                    if(each == "ROLE_ROLLOUT_MANAGER") {return true} 
                })
                $rootScope.isVendor = account.data.authorities.find(function (each){
                    if(each == "ROLE_ROLLOUT_VENDOR") {return true} 
                })


        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    var userLatitude = position.coords.latitude;
                    var userLongitude = position.coords.longitude;
                    
                    getLatLng(userLatitude, userLongitude);
                },
                function () {
                    callConfigLatLng()
                    console.error("Error: The Geolocation service failed.");
                }
            );
        } else {
            callConfigLatLng()
            console.error("Error: Your browser doesn't support geolocation.");
        }

        function getLatLng(lat, lng) {
            $rootScope.DEFAULT_LATITUDE = lat;
            $rootScope.DEFAULT_LONGITUDE = lng;
        }

        function callConfigLatLng(){
            var tempLat = "32.77059340204903";
            var tempLng = "-96.83874469246176"
            Configs.getLatLng(function(lat,lng){
                if(lat != null && lng != null){
                    $rootScope.DEFAULT_LATITUDE = lat;
                    $rootScope.DEFAULT_LONGITUDE = lng;
                }else{
                    getLatLng(tempLat, tempLng)
                }
                
            },function (error){
                getLatLng(tempLat,tempLng)
            })
        }
              

                //switch
               /*  Configs.get("Features", function (response) {
                    response.filter(function (value){

                        if(value.configKey == "ProjectExplorer"){
                            if(value.configValue == "True"){
                                $rootScope.showProjectExplorer = true
                            }else{
                                $rootScope.showProjectExplorer = false
                            }
                        } })}) */
            }

            function getAccountCatch () {
                _identity = null;
                _authenticated = false;
                deferred.resolve(_identity);
            }
        }

        function isAuthenticated () {
            return _authenticated;
        }

        function isIdentityResolved () {
            return angular.isDefined(_identity);
        }
        
    }
})();
